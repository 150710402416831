import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import NavBar from './layout/NavBar'
import { applyDensity, Density } from '@amzn/awsui-global-styles'
import NoRoutePage from './apps/home/NoRoutePage'
import './App.scss'
import { useAppContext } from '../context'
import useStore from './Store'
import { GLOBAL_BUSINESS_ENTITY, NO_USER_BUSINESS_ENTITY, SELECT_BUSINESS_ENTITY } from './Constant'
import SetupPlan from './apps/plan/SetupPlan'
import SetupRevision from './apps/plan/revision/SetupRevision'
import OrgHome from './apps/org/OrgHome'
import ProgramPage from './apps/program/ProgramPage'
import OrgDetail from './apps/org/OrgDetail'
import SetupDeliv from './apps/program/deliverables/SetupDeliv'
import DiscretionarySpendPage from './apps/program/discretionarySpend/DiscretionarySpendPage'
import CreateEditDiscretionarySpend from './apps/program/discretionarySpend/CreateEditDiscretionarySpend'
import EgretAuthorizer from '../auth/EgretAuthorizer'
import Unauthorized from './apps/home/Unauthorized'
import NoBusinessEntity from './apps/home/NoBusinessEntity'
import AuditsPage from './apps/audit/AuditsPage'
import { Sandbox } from './apps/program/Sandbox'
import HomePageSetUp from './apps/home/HomePageSetUp'
import SummariesAudits from './apps/summary/SummariesAudits'
import { Box, Flashbar } from '@amzn/awsui-components-react'
import { HEADER_BG_COLOR } from './apps/plan/Constants'
import _ from 'lodash'
import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar/interfaces'

interface businessEntityBannerContent {
    header: string
    type: FlashbarProps.Type
    content: React.ReactNode
}

export default function App({ userAlias, wsURLBase }: any) {
    document.body.classList.add('awsui-visual-refresh')
    applyDensity(Density.Compact)
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const [isLoading, setIsLoading] = useState(true)

    const setBusinessEntities = useStore((state) => state.setBusinessEntities)
    const setBusinessEntityMap = useStore((state) => state.setBusinessEntityMap)
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const setSelectedBusinessEntity = useStore((state) => state.setSelectedBusinessEntity)
    const defaultBE = useStore((state) => state.selectedBusinessEntity)
    const [initialBE, setInitialBE] = useState(defaultBE)
    const [businessEntityBannerContent, setBusinessEntityBannerContent] = useState<
        businessEntityBannerContent | any
    >({})
    const [businessEntityBannerItems, setBusinessEntityBannerItems] = useState<
        FlashbarProps.MessageDefinition[]
    >([])

    useEffect(() => {
        if (!businessEntityBannerContent.type) {
            setBusinessEntityBannerItems([])
            return
        }
        setBusinessEntityBannerItems([
            {
                header: businessEntityBannerContent.header,
                type: businessEntityBannerContent.type,
                content: businessEntityBannerContent.content,
                dismissible: true,
                dismissLabel: 'Dismiss',
                onDismiss: () => {
                    setBusinessEntityBannerContent({})
                },
            },
        ])
    }, [businessEntityBannerContent])

    const getUserBusinessEntity = async () => {
        const userBE = (await apiClient.get(`/falcon/user-business-entity`)).data
        const selected = {
            business_entity_id: !userBE ? NO_USER_BUSINESS_ENTITY : userBE.business_entity_id,
            business_entity_name: !userBE ? SELECT_BUSINESS_ENTITY : userBE.business_entity_name,
        }
        setSelectedBusinessEntity(selected)
        setInitialBE(selected)
        setIsLoading(false)
    }

    const getAllBusinessEntities = () => {
        apiClient
            .get('/falcon/business-entities')
            .then((response) => {
                const businessEntities = response.data
                const businessEntityMap = {}
                businessEntities.forEach((businessEntity) => {
                    businessEntityMap[businessEntity.business_entity_id] =
                        businessEntity.business_entity_name
                })
                setBusinessEntities(
                    businessEntities.filter(
                        (businessEntity) =>
                            businessEntity.business_entity_id !== GLOBAL_BUSINESS_ENTITY,
                    ),
                )
                setBusinessEntityMap(businessEntityMap)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        getAllBusinessEntities()
        getUserBusinessEntity().catch((err) => {
            console.error(err)
        })
    }, [])

    return (
        <Router>
            <div id='h' style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                <NavBar
                    setBusinessEntityBannerContent={setBusinessEntityBannerContent}
                    initialBusinessEntity={initialBE}
                />
            </div>
            {!_.isEmpty(businessEntityBannerItems) && (
                <div style={{ backgroundColor: HEADER_BG_COLOR }}>
                    <Box padding={{ vertical: 's', horizontal: 's' }}>
                        <Flashbar items={businessEntityBannerItems} />
                    </Box>
                </div>
            )}
            <Routes>
                <Route
                    path='/'
                    element={<EgretAuthorizer selectedBusinessEntity={selectedBusinessEntity} />}
                >
                    <Route path={'/'} element={<HomePageSetUp isLoading={isLoading} />} />
                    <Route path={'/all-plans'} element={<SetupPlan />} />
                    <Route path='/programs' element={<ProgramPage  />} />
                    <Route
                        path='/plan/:plan_id/revision/:revision_id'
                        element={<ProgramPage  />}
                    />
                    <Route
                        path='/plan/:plan_id/revision/:revision_id/program/:program_id/group/:group_id/discretionary-spend'
                        element={<DiscretionarySpendPage />}
                    />
                    <Route
                        path='/plan/:plan_id/revision/:revision_id/program/:program_id/discretionary-spend'
                        element={<DiscretionarySpendPage />}
                    />
                    <Route
                        path='/plan/:plan_id/revision/:revision_id/program/:program_id/group/:group_id/discretionary-spend/:expense_id'
                        element={<CreateEditDiscretionarySpend />}
                    />
                    <Route
                        path='/plan/:plan_id/revision/:revision_id/program/:program_id'
                        element={<SetupDeliv />}
                    />
                    <Route path='/orgs' element={<OrgHome />} />
                    <Route path='/plan/:id/revisions' element={<SetupRevision />} />
                    <Route path='/org/:id' element={<OrgDetail />} />
                    <Route
                        path='/sandbox'
                        element={<Sandbox userAlias={userAlias} wsURLBase={wsURLBase} />}
                    />
                    <Route path='/new-summaries' element={<SummariesAudits />} />
                    <Route path='/audits' element={<AuditsPage />} />
                </Route>
                <Route
                    path='/unauthorized'
                    element={<Unauthorized businessEntity={selectedBusinessEntity} />}
                />
                <Route path='/no-business-entity' element={<NoBusinessEntity />} />
                <Route path='*' element={NoRoutePage} />
            </Routes>
        </Router>
    )
}
