import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'

import { ModuleRegistry } from 'ag-grid-community'
import { ClientSideRowModelModule } from 'ag-grid-community'
import { RowEvent, ServerSideRowModelModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { ExcelExportModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { SetFilterModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { InfiniteRowModelModule } from 'ag-grid-community'
import './SummaryView.scss'
import { LicenseManager } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { agGridLicense } from '@amzn/ag-bird/src/ag-grid-license/ag-grid-license'
import { RowGroupingModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { CustomCellRendererProps } from 'ag-grid-react'

LicenseManager.setLicenseKey(agGridLicense)

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ServerSideRowModelModule,
    ExcelExportModule,
    SetFilterModule,
    InfiniteRowModelModule,
    RowGroupingModule,
])

export default (props: CustomCellRendererProps) => {
    const { node } = props

    let programName
    if (node.group && node.level >= 0) {
        const children = node?.allLeafChildren
        if (!_.isEmpty(children)) {
            programName = children[0].data['program_name']
        }
    }
    const [expanded, setExpanded] = useState(node.expanded)

    useEffect(() => {
        const expandListener = (event: RowEvent) => setExpanded(event.node.expanded)

        node.addEventListener('expandedChanged', expandListener)

        return () => {
            node.removeEventListener('expandedChanged', expandListener)
        }
    }, [])

    const onClick = useCallback(() => node.setExpanded(!node.expanded), [node])
    return (
        <div>
            {node.group && (
                <>
                    <div
                        style={{
                            cursor: 'pointer',
                            transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
                            display: 'inline-block',
                        }}
                        onClick={onClick}
                    ></div>
                    &nbsp;
                    <span className={node.footer ? 'ag-row-group-leaf-indent' : ''}>
                        {node.footer
                            ? programName
                                ? `${programName} Total`
                                : 'Total'
                            : programName}
                    </span>
                </>
            )}
            {node.rowPinned == 'bottom' && <>{node?.data?.program}</>}
        </div>
    )
}
