import { TextContent } from '@amzn/awsui-components-react'
import { GRAND_TOTAL_COLOR_CODE, hideZerosAndTruncateNumbers } from '../summary/SummaryUtil'
import { Chip } from '@mui/material'
import { shortenStringWithEllipsis, tooltipWrapper } from '../../common/Util'
import { PLAN_REVISION_STATUS_IDS } from '../plan/Constants'
import _ from 'lodash'
import { GetContextMenuItemsParams } from 'ag-grid-community'
import { ALERT_TYPES } from '../../Constant'
import {
    deliverableCellRenderer,
    deliverableFilterValueFormatter,
    deliverablePriorityBadgeCellRenderer,
    nameComparator,
    priorityComparator,
} from '../summary/NewSummariesUtil'
import ProgramCellRenderer from '../summary/ProgramCellRenderer'
import { addCommentMenuItems } from '../comment/CommentUtil'
import { ColDef } from 'ag-grid-community/dist/types/core/entities/colDef'
import PriorityBadgeCellRenderer from '../summary/PriorityBadgeCellRenderer'

export const TIME_OUT_VALUE_MS = 60000
export const INACCESSIBLE_COLOR = '#e3dfdf'
export const DEFAULT_COLOR = '#ffffff'
export const NON_EDITABLE_COLOR = '#e8ebeb'
const INITIAL_BORDER_COLOR = '#110d0d'

export const HEARTBEAT_INTERVAL_MS = 3000
export const HEARTBEAT_TIMEOUT_MS = 50000

export enum HEADCOUNT_TYPE {
    ct = 'C&T',
    ff = 'FF',
    total = 'Total',
}

export const getDifferenceArray = (array1, array2) => {
    const array2Ids = array2.map((hc) => (hc.headcount_id, hc.headcount_value))
    return array1.filter((item) => !array2Ids.includes((item.headcount_id, item.headcount_value)))
}
export const formatHistoryPanelSearchOptions = (items, label, type) => {
    return items
        .flatMap((item) => {
            return {
                label: label ? item[label] : item,
                type: type,
            }
        })
        .sort((a, b) => a.label.localeCompare(b.label))
}

export const filterSandboxHistory = (items, filterValue) => {
    return items.filter((est: any) => {
        const label = filterValue.label
        switch (filterValue.type) {
            case 'Group':
                return est.group_name === label
            case 'Program':
                return est.program_name === label
            case 'Deliverable':
                return est.deliverable_name === label
            case 'User':
                return est.updated_by === label
            default:
                break
        }
    })
}

export const generateSubmitDisableReason = (locked, lockedRevision) => {
    if (lockedRevision) {
        return 'Latest Revision has been locked. Submission cannot be initiated.'
    } else {
        return locked
            ? "There is an ongoing submission. Please wait until it's complete to start a new submission."
            : 'There are no new headcounts to submit.'
    }
}

export const generateCellStyle = (params: any) => {
    if (params.node.footer) {
        return { background: GRAND_TOTAL_COLOR_CODE }
    }
    return { background: NON_EDITABLE_COLOR }
}

export function secondsSinceEpoch() {
    return Math.floor(Date.now() / 1000)
}

export const getCellIdentifier = (headcountId) => {
    const headcountIDArr = headcountId.split('@')
    if (headcountIDArr.length < 2) {
        console.error('Invalid headcount_id while updating row_selection', headcountIDArr)
        return
    }
    const columnId = headcountIDArr[0] // group_id
    const rowId = headcountIDArr[1] // deliverable_id#program_id
    if (!rowId || !columnId) {
        console.error('Invalid row_id or column_id while updating row_selection')
        return
    }
    return {
        columnId: columnId,
        rowId: rowId,
    }
}

export const updateRowItem = (newItem, groupId, headcountType, payload, showFTEEstimation) => {
    const headcountValue = showFTEEstimation ? payload.headcount_value : payload.fte_month_value
    const previousValue = showFTEEstimation
        ? payload.previous_value
        : payload.previous_fte_month_value
    const updatedTotal = headcountValue - previousValue

    newItem[`${groupId}_${headcountType}_hc_val`] = payload.headcount_value
    newItem[`${groupId}_${headcountType}_fte_val`] = payload.fte_month_value
    newItem[`${groupId}_${headcountType}`] = headcountValue
    newItem[`${groupId}_${headcountType}_id`] = payload.headcount_id
    if (headcountType === 'ct' || headcountType === 'ff') {
        newItem[`total_${headcountType}`] = Math.max(
            newItem[`total_${headcountType}`] + updatedTotal,
            0,
        )
    }
    newItem['total'] = newItem[`total_ct`] + newItem[`total_ff`]
    newItem[`${groupId}_${headcountType}_updated_by`] = payload.updated_by
    newItem[`${groupId}_${headcountType}_updated_at`] = payload.updated_at
    newItem[`${groupId}_${headcountType}_previous_value`] = previousValue
    newItem['scoped'] = newItem['total'] > 0
}

export const parsePriority = (inputStr, defaultVal) => {
    const parsed = parseInt(inputStr.toLowerCase().replace('p', '').trim())
    return isNaN(parsed) ? defaultVal : parsed
}

export const getProgramPriorityFromGroup = (params, defaultReturnVal, isExport) => {
    // for export, we only want to display priority once for the group
    // for filter content, we want to get the parsed priority value
    if (!params.data && params?.node?.groupData) {
        // group export name cannot be the number 0 or a blank space
        const groupData = params.node.groupData['program']
        const priority = parsePriority(
            parseProgramNameAndPriorityField(groupData)?.priority ?? '',
            isExport ? '-' : defaultReturnVal,
        )
        return isExport ? priority.toString() : priority
    }
    return isExport
        ? defaultReturnVal
        : parsePriority(params.data?.priority ?? '', defaultReturnVal)
}

export const getProgramNameFromGroup = (params, defaultReturnVal, isExport) => {
    if (!params.data && params?.node?.groupData) {
        const groupData = params.node.groupData['program']
        return parseProgramNameAndPriorityField(groupData)?.program_name ?? defaultReturnVal
    }
    return isExport ? defaultReturnVal : (params.data?.program_name ?? defaultReturnVal)
}
export const sortStringFilterValues = (val1, val2) => {
    const normalize = (val) => {
        return val.toLowerCase().trim()
    }
    return normalize(val1) > normalize(val2) ? 1 : -1
}

export const getDeliverablePriorityValue = (params) => {
    if (!params.data && params?.node?.groupData) {
        return ''
    }
    const deliPriority = params.data?.deliverable_name?.priority || ''
    return deliPriority.toUpperCase()
}
const deliverablePriorityComparatorWithGroups = (value1, value2, node1, node2, isDesc) => {
    if (node1?.parent?.key === node2.parent?.key && (!node1?.group && !node2?.group)) {
        return parsePriority(value1 ?? '', Number.POSITIVE_INFINITY) < parsePriority(value2 ?? '', Number.POSITIVE_INFINITY) ? -1 : 1
    }
    return !isDesc ? node1.rowIndex-node2.rowIndex : node2.rowIndex-node1.rowIndex
}

const deliverableNameComparatorWithGroups = (value1, value2, node1, node2, isDesc) => {
    if (node1?.parent?.key === node2.parent?.key && (!node1?.group && !node2?.group)) {
        return value1?.name?.localeCompare(value2?.name)
    }
    return !isDesc ? node1.rowIndex-node2.rowIndex : node2.rowIndex-node1.rowIndex
}

export const getInitialColDefs = () => {
    return [
        {
            field: 'program_name_and_priority',
            rowGroup: true,
            hide: true,
            suppressToolPanel: true,
            sortable: false,
        },
        {
            field: 'priority',
            headerName: 'PP',
            groupTotalRow: undefined,
            floatingFilter: true,
            filter: 'agNumberColumnFilter',
            showRowGroup: 'program_name_and_priority',
            initialWidth: 100,
            hide: false,
            suppressToolPanel: true,
            cellRenderer: PriorityBadgeCellRenderer,
            filterParams: {
                buttons: ['reset'],
                includeBlanksInEquals: false,
                includeBlanksInNotEqual: false,
                includeBlanksInLessThan: false,
                includeBlanksInGreaterThan: false,
                includeBlanksInRange: false,
            },
            filterValueGetter: (params) => getProgramPriorityFromGroup(params, undefined, false),
            valueGetter: (params) => getProgramPriorityFromGroup(params, '', true),
            valueFormatter: (params) => getProgramPriorityFromGroup(params, '', true),
            comparator: priorityComparator,
            cellStyle: (params) => generateCellStyle(params),
            sort: 'asc',
            initialSortIndex: 1,
        },
        {
            field: 'program',
            headerName: 'Program',
            filter: 'agSetColumnFilter',
            floatingFilter: true,
            showRowGroup: 'program_name_and_priority',
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
                innerRenderer: ProgramCellRenderer,
            },
            cellStyle: (params) => generateCellStyle(params),
            filterValueGetter: (params) => getProgramNameFromGroup(params, '', false),
            valueFormatter: (params) => getProgramNameFromGroup(params, '', true),
            comparator: nameComparator,
            initialSortIndex: 0,
            sort: 'asc',
            filterParams: {
                comparator: (prog1, prog2) => sortStringFilterValues(prog1 ?? '', prog2 ?? ''),
            },
        },
        {
            field: 'deliverable_priority',
            headerName: 'DP',
            groupTotalRow: undefined,
            floatingFilter: true,
            suppressToolPanel: true,
            hide: false,
            filter: 'agSetColumnFilter',
            initialWidth: 100,
            cellRenderer: (params) => deliverablePriorityBadgeCellRenderer(params),
            valueGetter: getDeliverablePriorityValue,
            comparator: deliverablePriorityComparatorWithGroups,
            cellStyle: (params) => generateCellStyle(params),
            valueFormatter: getDeliverablePriorityValue,
        },
        {
            field: 'deliverable_name',
            headerName: 'Deliverable',
            filter: 'agSetColumnFilter',
            floatingFilter: true,
            cellStyle: (params) => generateCellStyle(params),
            cellRenderer: (params) => deliverableCellRenderer(params, false),
            tooltipValueGetter: (params) => {
                if (!params.data || !Object.keys(params.data).includes('deliverable_name')) {
                    return ''
                }
                return params.data['deliverable_name']['name']
            },
            filterParams: {
                valueFormatter: deliverableFilterValueFormatter,
                comparator: (deli1, deli2) =>
                    sortStringFilterValues(deli1?.name ?? '', deli2?.name ?? ''),
            },
            comparator: deliverableNameComparatorWithGroups,
            // for the export case we need valueFormatter else it will print [Object Object]
            valueFormatter: (params) => {
                if (!params.data || !Object.keys(params.data).includes('deliverable_name')) {
                    return ''
                }
                return params.data?.deliverable_name?.name
            },
        },

        {
            headerName: 'Total (CT + FF)',
            field: 'total',
            cellRenderer: (params) => {
                return hideZerosAndTruncateNumbers(params)
            },
            aggFunc: 'sum',
            floatingFilter: false,
            suppressHeaderFilterButton: true,
            cellStyle: (params) => generateCellStyle(params),
        },
        {
            headerName: 'Total',
            children: [
                {
                    ...generateCTFFSubColumnAttributes('total', 'ct'),
                    cellStyle: (params) => generateCellStyle(params),
                },
                {
                    ...generateCTFFSubColumnAttributes('total', 'ff'),
                    cellStyle: (params) => generateCellStyle(params),
                },
            ],
        },
    ]
}

export const DEFAULT_COL_DEFS: ColDef = {
    lockPosition: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    initialWidth: 100,
    suppressHeaderMenuButton: false,
    suppressHeaderContextMenu: true,
    menuTabs: ['generalMenuTab', 'columnsMenuTab'],
    headerClass: 'ag-right-aligned-header',
}

export const generateCTFFSubColumnAttributes = (id, type) => {
    return {
        headerName: HEADCOUNT_TYPE[type],
        field: `${id}_${type}`,
        type: 'numericColumn',
        aggFunc: 'sum',
        cellRenderer: (params) => {
            return hideZerosAndTruncateNumbers(params)
        },
        floatingFilter: false,
        suppressHeaderFilterButton: true,
    }
}

export const generateSplitPanelItemBadge = (textToDisplay, color, textLength) => {
    if (!textToDisplay) {
        return <></>
    }

    const isShorten = textToDisplay.length > textLength
    const object = (
        <Chip
            sx={{
                height: 'auto',
                '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                },
                backgroundColor: color,
            }}
            variant={'filled'}
            label={
                <TextContent>
                    <p style={{ fontSize: '0.7em' }}>
                        {isShorten
                            ? shortenStringWithEllipsis(textToDisplay, textLength)
                            : textToDisplay}
                    </p>
                </TextContent>
            }
        />
    )
    return isShorten ? tooltipWrapper(textToDisplay, object) : object
}

export const generateHCCellStyle = (
    params,
    group,
    userGroupSet,
    hcType,
    selectedPlanRef,
    activeUsers,
) => {
    // user does not have permission to access the group
    if (params.node.footer) {
        return { background: GRAND_TOTAL_COLOR_CODE }
    } else if (params.node.group || isPinnedBottomRow(params)) {
        return { background: NON_EDITABLE_COLOR }
    } else {
        if (!userGroupSet.has(group.group_id) || selectedPlanRef.current.value['is_locked']) {
            return {
                backgroundColor: INACCESSIBLE_COLOR,
            }
        } else {
            let borderColor = INITIAL_BORDER_COLOR
            let borderWidth = 'thin'
            Object.keys(activeUsers).forEach((user) => {
                if (
                    params?.data &&
                    params?.data[`${group.group_id}_${hcType}_locked_user`] == user
                ) {
                    borderColor = activeUsers[user]
                    borderWidth = 'medium'
                }
            })
            return {
                borderWidth: borderWidth,
                borderColor:
                    params?.data && params?.data[`${group.group_id}_${hcType}_locked`]
                        ? borderColor
                        : DEFAULT_COLOR,
                backgroundColor:
                    params?.data && params?.data[`${group.group_id}_${hcType}_group_locked`]
                        ? INACCESSIBLE_COLOR
                        : DEFAULT_COLOR,
            }
        }
    }
}

export const isPinnedBottomRow = (params) => {
    // node id b-0 points to the bottom pinned row : BIS Data row
    return params.node?.id === 'b-0'
}

export const isHCCellEditable = (params, group, userGroupSet, selectedPlanRef, hcType) => {
    if (!params?.data || isPinnedBottomRow(params)) {
        return false
    }
    const is_plan_unlocked = !selectedPlanRef.current.value['is_locked']
    const is_user_allowed = userGroupSet.has(group.group_id)
    const is_cell_locked =
        params?.data[`${group.group_id}_${hcType}_locked`] !== undefined &&
        params?.data[`${group.group_id}_${hcType}_locked`]
    const is_group_locked = params?.data[`${group.group_id}_${hcType}_group_locked`]
    return is_plan_unlocked && is_user_allowed && !(is_cell_locked || is_group_locked)
}

export const generateBannerContent = (id, type, content, onDismiss, header = '') => {
    return {
        type: type,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => onDismiss([]),
        id: id,
        header: header,
    }
}

export const isRevisionClosed = (latestRevision) => {
    if (_.isEmpty(latestRevision)) return
    return (
        latestRevision.is_locked ||
        latestRevision.revision_status === PLAN_REVISION_STATUS_IDS.PROGRAM_CREATION
    )
}

export const getDeliverableContextMenuItem = (
    params: GetContextMenuItemsParams,
    apiClient,
    setMetadata,
    setIsDetailModalVisible,
    loadSelectObject,
    setBannerItems,
    userRolesMetadata,
    setCommentMode,
    setIsSidePanelVisible,
    setSelectedProgram,
    setSelectedRole,
    setSelectedDeliverable,
    setCommentDeliverableOptions,
    selectedPlan,
    stage,
) => {
    const isFooter = params.node?.footer ?? false
    return !isFooter
        ? [
              {
                  name: `View Deliverable Details`,
                  action: () => {
                      const programId = params.node?.data['program_id']
                      const deliverableId = params.node?.data['deliverable_id']
                      apiClient
                          .get(
                              `/plan/${selectedPlan['plan_id']}/revision/${selectedPlan['revisions'][0]['revision_id']}/program/${programId}/deliverable/${deliverableId}`,
                          )
                          .then((res) => {
                              const deliverable = res.data
                              setMetadata(deliverable)
                              setIsDetailModalVisible(true)
                              loadSelectObject(deliverable, 'deliverable')
                          })
                          .catch((error) => {
                              console.error(error)
                              setBannerItems([
                                  generateBannerContent(
                                      'error_message_1',
                                      ALERT_TYPES.ERROR,
                                      `Deliverable details not found - Please make sure the program exists.`,
                                      setBannerItems,
                                  ),
                              ])
                          })
                  },
              },
              addCommentMenuItems(
                  false,
                  userRolesMetadata,
                  setCommentMode,
                  setIsSidePanelVisible,
                  setSelectedProgram,
                  setSelectedRole,
                  setSelectedDeliverable,
                  setCommentDeliverableOptions,
                  selectedPlan,
              ),
          ]
        : []
}

export const parseProgramNameAndPriorityField = (fieldValue) => {
    if (!fieldValue) {
        return { program_name: '', priority: '' }
    }
    const fieldSplit = fieldValue.split('@@')
    return { program_name: fieldSplit[0], priority: fieldSplit[1] }
}

export const getProgramContextMenuItem = (
    params: GetContextMenuItemsParams,
    apiClient,
    setMetadata,
    setIsDetailModalVisible,
    loadSelectObject,
    setBannerItems,
    userRolesMetadata,
    setCommentMode,
    setIsSidePanelVisible,
    setSelectedProgram,
    setSelectedRole,
    setSelectedDeliverable,
    setCommentDeliverableOptions,
    selectedPlan,
    stage,
) => {
    const isFooter = params.node?.footer ?? false
    return !isFooter
        ? [
              {
                  name: `View Program Details`,
                  action: () => {
                      // all programs that show up in sandbox will at least have one deliverable
                      const programId = params.node?.allLeafChildren[0]['data']['program_id']
                      apiClient
                          .get(
                              `/plan/${selectedPlan['plan_id']}/revision/${selectedPlan['revisions'][0]['revision_id']}/program/${programId}`,
                          )
                          .then((res) => {
                              let program = res.data
                              program = {
                                  ...program,
                                  stl_alias: program['stl_alias'] ? [program['stl_alias']] : [],
                                  registered_users: program['registered_users']
                                      ? program['registered_users']
                                      : [],
                              }
                              setMetadata(program)
                              setIsDetailModalVisible(true)
                              loadSelectObject(program, 'program')
                          })
                          .catch((error) => {
                              console.error(error)
                              setBannerItems([
                                  generateBannerContent(
                                      'error_message_1',
                                      ALERT_TYPES.ERROR,
                                      `Program details not found - Please make sure the program exists.`,
                                      setBannerItems,
                                  ),
                              ])
                          })
                  },
              },
              addCommentMenuItems(
                  true,
                  userRolesMetadata,
                  setCommentMode,
                  setIsSidePanelVisible,
                  setSelectedProgram,
                  setSelectedRole,
                  setSelectedDeliverable,
                  setCommentDeliverableOptions,
                  selectedPlan,
              ),
          ]
        : []
}

export const isCellById = (params, id: string) => {
    return params.column?.colId === id || params.node?.field === id
}

export const getSandboxGetContextMenuItemsGridOption = (
    params,
    apiClient,
    setProgramDetail,
    setIsProgramDetailModalVisible,
    loadSelectObject,
    setBannerItems,
    userRolesMetadata,
    setCommentMode,
    setIsSidePanelVisible,
    setSelectedProgram,
    setSelectedRole,
    setSelectedDeliverable,
    setCommentDeliverableOptions,
    selectedPlan,
    setDeliverableDetail,
    setIsDeliverableDetailModalVisible,
    stage,
) => {
    return isCellById(params, 'program_name_and_priority')
        ? getProgramContextMenuItem(
              params,
              apiClient,
              setProgramDetail,
              setIsProgramDetailModalVisible,
              loadSelectObject,
              setBannerItems,
              userRolesMetadata,
              setCommentMode,
              setIsSidePanelVisible,
              setSelectedProgram,
              setSelectedRole,
              setSelectedDeliverable,
              setCommentDeliverableOptions,
              selectedPlan,
              stage,
          )
        : isCellById(params, 'deliverable_name')
          ? getDeliverableContextMenuItem(
                params,
                apiClient,
                setDeliverableDetail,
                setIsDeliverableDetailModalVisible,
                loadSelectObject,
                setBannerItems,
                userRolesMetadata,
                setCommentMode,
                setIsSidePanelVisible,
                setSelectedProgram,
                setSelectedRole,
                setSelectedDeliverable,
                setCommentDeliverableOptions,
                selectedPlan,
                stage,
            )
          : []
}
