export interface HomePageButton {
    name: string
    href: string
    external?: boolean
}
export const HC_REPORTS = `Tableau Reports`
export const PROGRAMS_SECTION_DESC = 'View and edit Program details.'
export const SUMMARIES_AND_AUDITS_SECTION_DESC = 'View summary and audit reports.'

export const PLAN_SECTION_DESC = 'View OP1, OP2, and Off Cycle plans.'
export const ORG_SECTION_DESC = 'View organizational structure.'

//sub descriptions for each pages
export const HEADCOUNT_EDITOR_SUB_DESC = 'Edit headcount for your designated programs.'
export const PROGRAM_PAGE_SUB_DESC = 'View and edit programs.'
export const DELIVERABLE_PAGE_SUB_DESC = 'View and edit deliverables in a specific program.'
export const SPEND_PAGE_SUB_DESC =
    'View and edit discretionary spend information for a specific program.'
export const PLAN_PAGE_SUB_DESC = 'View and edit plans.'
export const REVISION_PAGE_SUB_DESC = 'Review and edit revisions in a specific plan.'
export const SUMMARIES_SUB_DESC = 'View organizational summary reports.'
export const AUDTIS_SUB_DESC = 'View organizational audits.'
export const ORGANIZATION_SUB_DESC = 'View and edit organizations.'
export const GROUP_SUB_DESC = 'View and edit groups in a specific organization.'

export const planButtons: HomePageButton[] = [
    {
        name: 'Plans',
        href: '/all-plans',
    },
]

export const orgButtons: HomePageButton[] = [
    {
        name: 'Organizations',
        href: '/orgs',
    },
    // todo: uncomment when my groups page has been developed
    // {
    //     name: 'My Groups',
    //     href: '',
    // },
]

export const programButtons: HomePageButton[] = [
    {
        name: 'Program List',
        href: '/programs',
    },
    {
        name: 'HC Editor',
        href: '/sandbox',
    },
    // todo: uncomment when spend estimate page is revamped and available
    // {
    //     name: 'Spend Estimates',
    //     href: '',
    // },
]

export const summariesAndAuditButtons: HomePageButton[] = [
    {
        name: 'Summaries',
        href: '/new-summaries',
    },
    {
        name: 'Tableau Reports',
        href: '#',
        external: true,
    },
    {
        name: 'Audits',
        href: '/audits',
    },
]
