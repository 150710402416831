import { useEffect, useState } from 'react'
import PlanList from './PlanList'
import CreatePlan from './CreatePlan'
import { useAppContext } from '../../../context'
import {
    Box,
    ContentLayout,
    Flashbar,
    SpaceBetween,
    StatusIndicator,
    Link,
} from '@amzn/awsui-components-react'
import { convertToLocalTime } from '../../common/Util'
import { ALERT_TYPES, MODAL_MODES, NO_USER_BUSINESS_ENTITY } from '../../Constant'
import useStore from '../../Store'
import HeaderTemplate from '../reusable/HeaderTemplate'
import DeleteModal from '../reusable/DeleteModal'
import DatePopover from '../reusable/DatePopover'
import BusinessEntityRefresh from '../reusable/BusinessEntityRefresh'
import { HEADER_BG_COLOR, PLAN_STATUS_TYPES, STATUS_TO_INDICATOR_MAP } from './Constants'
import { useNavigate } from 'react-router-dom'
import { PLAN_PAGE_SUB_DESC } from '../home/HomePageConstants'

const SetupPlan = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const plans = useStore((state) => state.allPlans)
    const setPlans = useStore((state) => state.setAllPlans)
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const validBESelected =
        selectedBusinessEntity.business_entity_id &&
        selectedBusinessEntity.business_entity_id !== NO_USER_BUSINESS_ENTITY
    const [columnDefinitions, setColumnDefinitions] = useState<any[]>([])
    const [tableVisibleColumns, setTableVisibleColumns] = useState<any[]>([])
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [modalMode, setModalMode] = useState<MODAL_MODES>(MODAL_MODES.NOT_SET)
    const [selectedPlanMetadata, setSelectedPlanMetadataMetadata] = useState<any>(undefined)
    const [isPlanLoading, setIsPlanLoading] = useState(true)
    const [alertItems, setAlertItems] = useState<any[]>([])
    const canAdmin = useStore((state) => state.canAdmin)
    const history = useNavigate()

    const handleCreate = () => {
        setModalMode(MODAL_MODES.CREATE)
        setCreateModalVisible(true)
    }

    const handleEdit = () => {
        setModalMode(MODAL_MODES.EDIT)
        setCreateModalVisible(true)
    }

    const handleAddAlertContent = (message: string, alertType: any) => {
        const alertId = `${appContext.userProps.userAlias}-${Date.now()}`
        const newAlert = {
            onDismiss: () => {
                setAlertItems((items) => items.filter((item) => item.id !== alertId))
            },
            dismissible: true,
            content: message,
            type: alertType,
            id: alertId,
        }
        setAlertItems([newAlert, ...alertItems])
    }

    const handleDelete = () => {
        setDeleteModalVisible(true)
    }

    const handleCreateModalClose = () => {
        setSelectedPlanMetadataMetadata(undefined)
        setCreateModalVisible(false)
    }

    useEffect(() => {
        buildColumnDefinitions()
    }, [])

    const onPlanClick = (event, item) => {
        const planId = item['plan_id']
        event.preventDefault()
        history(`/plan/${planId}/revisions`, {
            state: {
                plan_id: planId,
                revisions: item['revisions'],
                plan_name: item['plan_name'],
            },
        })
    }

    const buildColumnDefinitions = () => {
        let columnDefs: any[] = []
        // first define the table view and form views for predefined /common attributes
        const attributeInfo = [
            ['plan_id', ''],
            ['plan_name', 'Name'],
            ['plan_status', 'Status'],
            ['year', 'Year'],
            ['plan_type', 'Type (OP1/OP2/Off-Cycle)'],
            ['stl_leader', 'STL'],
            ['admin_backup', 'Admin Backup'],
            ['overhead_factor', 'Overhead Factor'],
            ['number_of_revisions', 'Revisions'],
            ['created_by', 'Created By'],
            ['updated_by', 'Updated By'],
            ['created_at', 'Created At'],
            ['updated_at', 'Updated At'],
        ]
        const visibleColumns: any[] = attributeInfo.map((attr) => {
            const attrId = attr[0]
            if (attrId !== 'plan_id') {
                return attr[0]
            }
        })

        // these next fields are just metadata for the table view
        columnDefs = attributeInfo.map((attr) => {
            const [attrId, attrName] = attr
            let cellType = (item) => item[attrId]
            if (attrId === 'created_at' || attrId === 'updated_at') {
                cellType = (item) => <DatePopover date={convertToLocalTime(item.created_at)} />
            } else if (attrId === 'plan_status') {
                cellType = (item) => (
                    <StatusIndicator type={STATUS_TO_INDICATOR_MAP[item.plan_status]}>
                        {PLAN_STATUS_TYPES[item.plan_status]}
                    </StatusIndicator>
                )
            } else if (attrId === 'plan_name') {
                cellType = (item) => (
                    <Link
                        onFollow={(event) => onPlanClick(event, item)}
                        href={`/plan/${item['plan_id']}/revisions`}
                    >
                        {item.plan_name}
                    </Link>
                )
            }
            return {
                id: attrId,
                header: attrName,
                cell: cellType,
                sortingField: attrId,
            }
        })

        setColumnDefinitions(columnDefs)
        setTableVisibleColumns(visibleColumns)
    }

    const deletePlan = () => {
        setDeleteModalVisible(false)
        setIsPlanLoading(true)
        apiClient
            .delete(`/plan/${selectedPlanMetadata.plan_id}`)
            .then(() => {
                getAllPlans()
                handleAddAlertContent(
                    `Successfully deleted ${selectedPlanMetadata.plan_name} from ${selectedBusinessEntity.business_entity_name}.`,
                    ALERT_TYPES.SUCCESS,
                )
                setSelectedPlanMetadataMetadata(undefined)
            })
            .catch((error) => {
                handleAddAlertContent(
                    `Failed to delete ${selectedPlanMetadata.plan_name} from ${selectedBusinessEntity.business_entity_name} : ${error.response.data}`,
                    ALERT_TYPES.ERROR,
                )
                setSelectedPlanMetadataMetadata(undefined)
                console.error(error)
                setIsPlanLoading(false)
            })
    }

    const getAllPlans = () => {
        setIsPlanLoading(true)
        apiClient
            .get(`plan/business-entity/${selectedBusinessEntity.business_entity_id}?year=`)
            .then((response) => {
                const sorted = response.data.sort((plan1, plan2) =>
                    plan1['year'] > plan2['year'] ? -1 : 1,
                )
                const allPlans = sorted.map((plan) => ({
                    ...plan,
                    ['number_of_revisions']: plan['revisions'].length,
                    ['plan_name']: `${plan['year'] || ''} ${plan['plan_type'] || ''}`,
                    ['business_entity_id']: selectedBusinessEntity.business_entity_id,
                }))
                setPlans(allPlans)
                setIsPlanLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setPlans([])
                setIsPlanLoading(false)
            })
    }

    useEffect(() => {
        if (!validBESelected) {
            setIsPlanLoading(false)
            setPlans([])
        }
        BusinessEntityRefresh(selectedBusinessEntity.business_entity_id, getAllPlans)
        buildColumnDefinitions()
        setSelectedPlanMetadataMetadata(undefined)
        setCreateModalVisible(false)
        setDeleteModalVisible(false)
    }, [selectedBusinessEntity])

    const lastRevisionName = localStorage.getItem('deleted_last_revision_name')
    const lastRevisionPlanName = localStorage.getItem('deleted_last_revision_plan_name')
    localStorage.clear()
    if (lastRevisionName && lastRevisionPlanName) {
        handleAddAlertContent(
            `Successfully deleted last revision ${lastRevisionName} and the plan ${lastRevisionPlanName}.`,
            ALERT_TYPES.SUCCESS,
        )
    }

    return (
        <ContentLayout
            defaultPadding
            headerBackgroundStyle={HEADER_BG_COLOR}
            headerVariant='high-contrast'
            header={
                <Box margin={{ top: 's', left: 's', right: 's' }}>
                    <SpaceBetween size='m'>
                        <HeaderTemplate
                            subtitle={PLAN_PAGE_SUB_DESC}
                            items={[
                                { text: 'Home', href: '/' },
                                { text: 'Plans', href: '' },
                            ]}
                        />
                        {!alertItems.length ? (
                            <></>
                        ) : (
                            <Flashbar items={alertItems} stackItems={true} />
                        )}
                    </SpaceBetween>
                </Box>
            }
        >
            <DeleteModal
                title={'Plan'}
                visible={deleteModalVisible}
                onDismiss={() => setDeleteModalVisible(false)}
                onDelete={deletePlan}
            />
            <Box margin={{ left: 's', right: 's' }}>
                <PlanList
                    canAdmin={canAdmin}
                    plans={plans}
                    selectedPlanMetadata={selectedPlanMetadata}
                    setSelectedPlanMetadata={setSelectedPlanMetadataMetadata}
                    columns={columnDefinitions}
                    visibleColumns={tableVisibleColumns}
                    onCreate={handleCreate}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    isLoading={isPlanLoading}
                />
            </Box>
            <CreatePlan
                modalMode={modalMode}
                setModalMode={setModalMode}
                visible={createModalVisible}
                selectedPlanMetadata={selectedPlanMetadata}
                closeModalHandler={handleCreateModalClose}
                refreshList={getAllPlans}
                handleAddAlertContent={handleAddAlertContent}
                plans={plans}
            />
        </ContentLayout>
    )
}

export default SetupPlan
