import {
    Alert,
    Box,
    Button,
    ContentLayout,
    FormField,
    Link,
    Flashbar,
    SelectProps,
    SpaceBetween,
    Select,
    Modal,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../../reusable/HeaderTemplate'
import { useEffect, useReducer, useState } from 'react'
import { useAppContext } from '../../../../context'
import useStore from '../../../Store'
import { useNavigate, useLocation } from 'react-router-dom'
import GenericSummaryTable from '../../reusable/GenericSummaryTable'
import {
    ALERT_TYPES,
    ALL_GROUP_SELECTION,
    EMPTY_SELECTION,
    MODAL_MODES,
    SELECT_MOVE_PROGRAM_OPTION,
} from '../../../Constant'
import {
    getFormattedAttributes,
    getProgramColumnDefinitions,
    getProgramSummaryVisibleColumns,
    PROGRAM_SELECTION_IDS,
    programAttributes,
} from '../ProgramAttributes'
import DiscretionarySpendTable from './DiscretionarySpendTable'
import {
    forceTotalsRowToEndWithNumericInput,
    getMonthColumnDefinitions,
    getSpendColumnDefinitions,
    getSpendVisibleColumns,
    monthsVisibleColumns,
} from './DiscretionarySpendAttributes'
import DeleteModal from '../../reusable/DeleteModal'
import CreateEditModal from '../../program/CreateEditModal'
import { generateInitialState } from '../ProgramConstants'
import {
    convertToMoneyFormat,
    emptyFn,
    filterVisibleColsByTerms,
    getAllProgramRevisionOptionsForPlan,
    getMetadataForPlan,
    getProgramNameWithoutActiveStatus,
} from '../../reusable/Utils'
import { getGroupOptions } from '../../../common/Util'
import DiscretionarySpendSummaryTable from './DiscretionarySpendSummaryTable'
import BusinessEntityRefresh from '../../reusable/BusinessEntityRefresh'
import DiscretionarySpendTableFooter from './DiscretionarySpendTableFooter'
import { programReducer, REDUCER_ACTIONS } from '../ProgramSharedUtils'
import RevisionAlert, { SOURCE_PAGE } from '../../reusable/RevisionAlert'
import { HEADER_BG_COLOR } from '../../plan/Constants'
import { SPEND_PAGE_SUB_DESC } from '../../home/HomePageConstants'

const DiscretionarySpendPage = () => {
    const appContext = useAppContext()
    const userProps = appContext.userProps
    const apiClient = appContext.apiClient
    const userGroups = useStore((state) => state.userGroups)
    const canEditPrograms = useStore((state) => state.canEditPrograms)
    const canAdmin = useStore((state) => state.canAdmin)

    const navigate: any = useNavigate()
    const location = useLocation()
    const paths = location.pathname.split('/')
    const planId = paths[2]
    const revisionId = paths[4]
    const programId = paths[6]
    const groupId = paths.includes('group') ? paths[8] : ''

    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    // formatted st value is the plan metadata
    const [selectedPlan, setSelectedPlan] = useState<any>(EMPTY_SELECTION)
    const [planMetadata, setPlanMetadata] = useState<any>({})

    const defaultGroupOption = getGroupOptions(
        selectedBusinessEntity.business_entity_id,
        userGroups,
    )

    let defaultGroupSelectOption =
        userGroups.length && groupId
            ? userGroups.find((group) => group.object_id === groupId)
            : { object_id: '', object_name: 'Select Group' }

    if (canAdmin) {
        defaultGroupOption.unshift(ALL_GROUP_SELECTION)
        defaultGroupSelectOption = {
            object_id: ALL_GROUP_SELECTION.value,
            object_name: ALL_GROUP_SELECTION.label,
        }
    }

    const getVisibleColumns = () => {
        const result: any[] = getSpendVisibleColumns()
        result.push('total_expenditure')
        return result.concat(monthsVisibleColumns)
    }
    const defaultVisibleColumns = getVisibleColumns()
    const allGroupVisibleColumns = structuredClone(defaultVisibleColumns)
    allGroupVisibleColumns.unshift('group_name')

    const [program, setProgram] = useState<any>({})
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [isSpendLoading, setIsSpendLoading] = useState(true)
    const [spends, setSpends] = useState<any[]>([])
    const [selectedSpends, setSelectedSpends] = useState<any>([])
    const [selectedRevision, setSelectedRevision] = useState<SelectProps.Option>(EMPTY_SELECTION)
    const [revisionOptions, setRevisionOptions] = useState<SelectProps.Option[]>([])
    const [isProgramLoading, setIsProgramLoading] = useState(true)
    const [alertItems, setAlertItems] = useState<any[]>([])
    const [isRevisionLocked, setIsRevisionLocked] = useState(false)
    const [groupOptions, setGroupOptions] = useState<any>(defaultGroupOption)
    const [selectedGroup, setSelectedGroup] = useState<any>(defaultGroupSelectOption)
    const [programViewModalVisible, setProgramViewModalVisible] = useState(false)
    const [programModalExpanded, setProgramModalExpanded] = useState(true)
    const [visibleColumns, setVisibleColumns] = useState(
        canAdmin ? allGroupVisibleColumns : defaultVisibleColumns,
    )
    const [moveModalVisible, setMoveModalVisible] = useState(false)
    const [selectedProgramOption, setSelectedProgramOption] = useState<any>(
        SELECT_MOVE_PROGRAM_OPTION,
    )
    const [selectedProgramError, setSelectedProgramError] = useState<string>('')
    const [programAlertContent, setProgramAlertContent] = useState<string>('')
    const [programSelectStatus, setProgramSelectStatus] = useState<any>('loading')
    const [programOptions, setProgramOptions] = useState<any[]>([])
    const [localColumnDefinitions, setLocalColumnDefinitions] = useState<any[]>([])

    const [programState, dispatchProgram] = useReducer(
        programReducer,
        generateInitialState(programAttributes),
    )

    const loadSelect = (target) => {
        dispatchProgram({
            type: REDUCER_ACTIONS.load,
            target: target,
        })
        return
    }

    useEffect(() => {
        if (!userGroups.length) {
            return
        }
        setGroupOptions(defaultGroupOption)
        setSelectedGroup(defaultGroupSelectOption)
    }, [userGroups])

    const getColumnDefinitions = () => {
        const result: any[] = getSpendColumnDefinitions()
        result.unshift({
            id: 'group_name',
            header: 'Group',
            cell: (item) => item.group_name,
            sortingField: 'group_name',
        })
        result.push({
            id: 'total_expenditure',
            header: `Total`,
            cell: (item) =>
                !item.ds_item_id ? (
                    <h4>{convertToMoneyFormat(item.total_expenditure)}</h4>
                ) : (
                    convertToMoneyFormat(item.total_expenditure)
                ),
            sortingComparator: (item1, item2) =>
                forceTotalsRowToEndWithNumericInput(item1, item2, 'total_expenditure'),
            minWidth: 90,
            sortingField: 'total_expenditure',
        })
        return result.concat(getMonthColumnDefinitions())
    }

    const columnDefinitions = getColumnDefinitions()

    const handleDelete = () => {
        if (!selectedGroup.object_id) {
            setIsSpendLoading(false)
            return
        }
        setDeleteModalVisible(false)
        setIsSpendLoading(true)
        const expenses_ids = selectedSpends.map((spend) => spend['ds_item_id']).join(',')
        apiClient
            .delete(`/discretionary_spend?ds_ids=${expenses_ids}`)
            .then(() => {
                handleAddAlertItem(
                    `Successfully delete expense(s) ${selectedSpends.map(
                        (spend) => spend.expense_title,
                    )}.`,
                    ALERT_TYPES.SUCCESS,
                )
                getSpends(selectedGroup.object_id, revisionId)
                setSelectedSpends([])
            })
            .catch((error) => {
                console.error(error)
                handleAddAlertItem(
                    `Failed to delete expense(s) ${selectedSpends.map(
                        (spend) => spend.expense_title,
                    )}: ${error.response.data}.`,
                    ALERT_TYPES.ERROR,
                )
            })
            .finally(() => {
                setIsSpendLoading(false)
            })
    }

    const handleMove = () => {
        const payload = {
            business_entity_id: selectedBusinessEntity.business_entity_id,
            source_program_id: programId,
            target_program_id: selectedProgramOption.value,
            user: appContext.userProps.userAlias,
            source_spends: selectedSpends,
        }

        apiClient
            .post(
                `/plan/${planId}/revision/${revisionId}/discretionary-spends/bulk-move`,
                JSON.stringify(payload),
            )
            .then(() => {
                handleAddAlertItem(
                    <SpaceBetween direction='horizontal' size='xxs'>
                        <Box>{`Successfully moved spend(s): ${selectedSpends.map(
                            (spend) => spend.expense_title,
                        )}.`}</Box>
                        Moved to
                        <Link
                            external
                            externalIconAriaLabel='Opens in a new tab'
                            href={`/plan/${planId}/revision/${revisionId}/program/${selectedProgramOption.value}/discretionary-spend`}
                        >
                            {`${getProgramNameWithoutActiveStatus(
                                selectedProgramOption,
                            )} spends page`}
                        </Link>
                        .
                    </SpaceBetween>,
                    ALERT_TYPES.SUCCESS,
                )
                setSelectedSpends([])
                setSelectedProgramOption(SELECT_MOVE_PROGRAM_OPTION)
                getSpends(selectedGroup.object_id, revisionId)
            })
            .catch((error) => {
                handleAddAlertItem(
                    `Failed to moved spends: ${selectedSpends.map((spend) => spend.expense_title)}
          : ${error.response.data}`,
                    ALERT_TYPES.ERROR,
                )
                console.error(error)
            })
            .finally(() => {
                setMoveModalVisible(false)
                window.scrollTo(0, 0)
            })
    }

    const formatPrograms = (programs) => {
        return programs
            .filter((prog) => prog.program_id !== program.program_id)
            .map((prog) => {
                return {
                    label: `${prog.program_name} - ${prog.is_active ? 'active' : 'inactive'}`,
                    value: prog.program_id,
                    iconName: prog.is_active ? 'status-positive' : 'status-negative',
                }
            })
            .sort((a, b) => a.label.localeCompare(b.label))
    }

    const getLocalPrograms = () => {
        apiClient
            .get(`/plan/${planId}/revision/${revisionId}/programs`)
            .then((res) => {
                const programs = res.data
                setProgramOptions(formatPrograms(programs))
                setProgramSelectStatus('finished')
            })
            .catch((error) => {
                console.error(error)
                setProgramSelectStatus('error')
            })
    }

    const handleAddAlertItem = (content, type: ALERT_TYPES) => {
        const id = `${userProps.userAlias}-${Date.now()}`
        const alert = {
            onDismiss: () => {
                setAlertItems((items) => items.filter((item) => item.id !== id))
            },
            dismissible: true,
            content: content,
            type: type,
            id: id,
        }
        setAlertItems([alert, ...alertItems])
    }

    const getAllRevisionOptions = () => {
        getAllProgramRevisionOptionsForPlan(
            apiClient,
            programId,
            revisionId,
            planId,
            setSelectedRevision,
            setRevisionOptions,
            handleAddAlertItem,
        )
    }

    const getProgram = (revisionId: string) => {
        setIsProgramLoading(true)
        apiClient
            .get(`/plan/${planId}/revision/${revisionId}/program/${programId}`)
            .then((res) => {
                let tmpProgram = res.data
                tmpProgram = {
                    ...tmpProgram,
                    stl_alias: tmpProgram['stl_alias'] ? [tmpProgram['stl_alias']] : [],
                    registered_users: tmpProgram['registered_users'] || [],
                }
                setProgram(tmpProgram)
            })
            .catch((error) => {
                console.error(error)
                handleAddAlertItem(`Unable to retrieve metadata for program.`, ALERT_TYPES.ERROR)
            })
            .finally(() => setIsProgramLoading(false))
    }

    const getSpends = (groupId: string, revisionId: string) => {
        if (!groupId) {
            setSpends([])
            setIsSpendLoading(false)
            return
        }
        const URL =
            groupId === ALL_GROUP_SELECTION.value
                ? `/plan/${planId}/revision/${revisionId}/program/${programId}/discretionary-spends`
                : `/group/${groupId}/plan/${planId}/revision/${revisionId}/program/${programId}/discretionary-spend`

        setIsSpendLoading(true)
        apiClient
            .get(URL)
            .then((res) => {
                const currentSpends = res.data
                if (!currentSpends || !currentSpends.length) {
                    setSpends([])
                    return
                }

                currentSpends.map((spend) => {
                    const group = groupOptions.find((group) => group.value === spend.group_id)
                    spend.group_name = group ? group.label : ''
                    return spend
                })
                setSpends(currentSpends)
            })
            .catch((error) => {
                console.error(error)
                handleAddAlertItem(`Unable to retrieve expenses data.`, ALERT_TYPES.ERROR)
            })
            .finally(() => setIsSpendLoading(false))
    }

    const loadPage = () => {
        getMetadataForPlan(apiClient, planId, setPlanMetadata, setSelectedPlan)
        getAllRevisionOptions()
        getProgram(revisionId)
        getSpends(selectedGroup.object_id, revisionId)
    }

    useEffect(() => {
        setLocalColumnDefinitions(
            getProgramColumnDefinitions(
                navigate,
                selectedPlan?.value,
                selectedRevision,
                programAttributes,
            ),
        )
    }, [planMetadata])

    useEffect(() => {
        BusinessEntityRefresh(selectedBusinessEntity, loadPage)
    }, [selectedBusinessEntity, revisionId])

    useEffect(() => {
        if (program.program_id) {
            getLocalPrograms()
        }
    }, [program])

    useEffect(() => {
        if (!selectedPlan || !selectedRevision.value) {
            return
        }

        apiClient
            .get(`/settings?keys=${PROGRAM_SELECTION_IDS.join(',')}`)
            .then((res) => {
                const selections = res.data
                setLocalColumnDefinitions(
                    getProgramColumnDefinitions(
                        navigate,
                        selectedPlan.value,
                        selectedRevision,
                        getFormattedAttributes(selections, programAttributes),
                    ).slice(1),
                )
            })
            .catch((err) => {
                console.error(err)
            })
    }, [selectedPlan, selectedRevision])

    return (
        <ContentLayout
            defaultPadding
            headerBackgroundStyle={HEADER_BG_COLOR}
            headerVariant='high-contrast'
            header={
                <Box margin={{ top: 's', left: 's', right: 's' }}>
                    <SpaceBetween size='m'>
                        <HeaderTemplate
                            subtitle={SPEND_PAGE_SUB_DESC}
                            items={[
                                { text: 'Home', href: '/' },
                                {
                                    text: `Programs`,
                                    href: `/plan/${planId}/revision/${revisionId}`,
                                },
                                {
                                    text: `${
                                        Object.keys(program).length > 0 ? program.program_name : ''
                                    } Discretionary Spend`,
                                    href: '',
                                },
                            ]}
                        />
                        {Boolean(alertItems.length) && (
                            <Flashbar items={alertItems} stackItems={true} />
                        )}
                        <RevisionAlert
                            planId={planId}
                            selectedRevisionId={revisionId}
                            sourcePage={SOURCE_PAGE.ESTIMATE}
                            setIsLocked={setIsRevisionLocked}
                            apiClient={apiClient}
                        />
                    </SpaceBetween>
                </Box>
            }
        >
            <Modal
                header={`Move Expense(s)`}
                visible={moveModalVisible}
                onDismiss={() => setMoveModalVisible(false)}
                footer={
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button variant='link' onClick={() => setMoveModalVisible(false)}>
                                Cancel
                            </Button>
                            <Button
                                variant='primary'
                                onClick={() => handleMove()}
                                disabled={selectedProgramOption.value === 'select_move_to_program'}
                            >
                                Move
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                <SpaceBetween direction='vertical' size='s'>
                    {programAlertContent && (
                        <Alert statusIconAriaLabel='Warning' type={ALERT_TYPES.WARNING}>
                            {programAlertContent}
                        </Alert>
                    )}
                    <FormField
                        label='Program'
                        description={'Move to program in current plan and revision.'}
                        errorText={selectedProgramError}
                    >
                        <Select
                            statusType={programSelectStatus}
                            loadingText='Loading programs'
                            selectedOption={selectedProgramOption}
                            onChange={({ detail }) => {
                                setSelectedProgramOption(detail.selectedOption)
                                setSelectedProgramError('')

                                if (detail.selectedOption.iconName === 'status-negative') {
                                    setProgramAlertContent('Inactive move to program selected. ')
                                } else {
                                    setProgramAlertContent('')
                                }
                            }}
                            options={programOptions}
                            filteringType='auto'
                        />
                    </FormField>
                    <DiscretionarySpendSummaryTable spends={selectedSpends} />
                </SpaceBetween>
            </Modal>
            <DeleteModal
                titleOverride={`Delete Expense`}
                descriptionOverride={`Delete permanently?`}
                buttonOverride={'Delete'}
                visible={deleteModalVisible}
                onDismiss={() => setDeleteModalVisible(false)}
                onDelete={() => handleDelete()}
            />
            <CreateEditModal
                isStlNotAdmin={!canAdmin && canEditPrograms}
                selectedRevision={selectedRevision}
                state={{
                    ...programState,
                    ['kingpin_goals']: program?.kingpin_goals || [],
                    ['important_links']: program?.important_links || [],
                }}
                selectedPlan={selectedPlan}
                inputChange={emptyFn}
                clearAllInputs={emptyFn}
                isDataUnmodified={true}
                isInputInvalid={false}
                onIsInputInvalidChanged={emptyFn}
                onIsDataUnmodifiedChanged={emptyFn}
                isAddingProgram={false}
                onAlertItemAdd={emptyFn}
                onDismiss={() => setProgramViewModalVisible(false)}
                visible={programViewModalVisible}
                onIsLoadingChange={false}
                isModalExpand={programModalExpanded}
                onIsModalExpandChange={setProgramModalExpanded}
                modalMode={MODAL_MODES.VIEW}
                programs={program}
                selectedPrograms={program}
                onSelectedProgramsChange={emptyFn}
                selectedProgramBusinessEntity={false}
                getLocalPrograms={false}
                getParentPrograms={false}
                initialKingpinGoals={program?.kingpin_goals ? program.kingpin_goals : []}
                initialImportantLinks={program?.important_links ? program.important_links : []}
                onImportantLinksChange={emptyFn}
                selectedPlanOptions={[]}
                onSelectPlanChange={emptyFn}
                selectedRevisionOptions={[]}
                onSelectRevisionChange={emptyFn}
                globalAttributesError={''}
                setGlobalAttributesError={emptyFn}
                allParentProgramPlans={[]}
                allParentProgramGlobalAttributes={[]}
                loadSelectProgram={emptyFn}
                hideLocalAttributes={false}
                refreshPage={emptyFn}
                fetchGlobalAttributesForPlan={emptyFn}
            />
            <Box margin={{ left: 's', right: 's' }}>
                <SpaceBetween size={'xxl'} direction={'vertical'}>
                    <GenericSummaryTable
                        itemsToShow={program && program.registered_users ? [program] : []}
                        actions={[]}
                        columns={[
                            {
                                id: 'prog_name_display',
                                header: 'Program',
                                cell: (e) => (
                                    <Link
                                        onFollow={() => {
                                            loadSelect(program)
                                            setProgramViewModalVisible(true)
                                        }}
                                    >
                                        {e.program_name}
                                    </Link>
                                ),
                                sortingField: 'program_name',
                            },
                            ...localColumnDefinitions,
                        ]}
                        visibleColumns={filterVisibleColsByTerms(
                            getProgramSummaryVisibleColumns(),
                            ['create', 'update', 'program_name'],
                        )}
                        defaultNameField={'Program'}
                        nameField={'program_name'}
                        isLoading={isProgramLoading || isSpendLoading}
                        objectType={'Program'}
                        wrapLines={false}
                        includePagination={false}
                    />
                    <DiscretionarySpendTable
                        selectedPlan={selectedPlan}
                        selectedRevision={selectedRevision}
                        onSelectedRevisionChange={setSelectedRevision}
                        revisionOptions={revisionOptions}
                        program={program}
                        spends={spends}
                        getSpends={getSpends}
                        selectedSpends={selectedSpends}
                        onSelectedSpendsChange={setSelectedSpends}
                        isRevisionLocked={isRevisionLocked}
                        onDelete={() => setDeleteModalVisible(true)}
                        isLoading={isSpendLoading}
                        columnDefinitions={columnDefinitions}
                        visibleColumns={visibleColumns}
                        onVisibleColumnsChange={setVisibleColumns}
                        defaultVisibleColumns={defaultVisibleColumns}
                        allGroupVisibleColumns={allGroupVisibleColumns}
                        selectedGroup={selectedGroup}
                        onSelectedGroupChange={setSelectedGroup}
                        groupOptions={groupOptions}
                        onMove={() => {
                            const copyDeliverableProgramId = localStorage.getItem(
                                'copy_deliverable_program_id',
                            )
                            if (copyDeliverableProgramId) {
                                setSelectedProgramOption(
                                    programOptions.find(
                                        (option) => option.value === copyDeliverableProgramId,
                                    ),
                                )
                                localStorage.clear()
                            }
                            setMoveModalVisible(true)
                        }}
                    />
                    <DiscretionarySpendTableFooter spends={spends} />
                </SpaceBetween>
            </Box>
        </ContentLayout>
    )
}

export default DiscretionarySpendPage
