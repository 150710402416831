// TODO initial setup, change once confirm
export const MAX_COMMENT_CHARACTOR_COUNT = 300
export const COMMENT_PANEL_PROG_DELI_MAX_TEXT_LENGTH = 20
export const COMMENT_PANEL_ROLE_NAME_MAX_TEXT_LENGTH = 45

export const enum COMMENT_PANEL_MODE {
    VIEW = 'VIEW',
    EDIT = 'EDIT',
    DISABLED = 'DISABLED',
}

export enum USER_ROLES {
    USER_ORGS = 'Org Manager',
    USER_REGISTERED_GROUPS = 'Group Manager',
    USER_PROGRAMS = 'STL',
    USER_ADMIN = 'Admin',
}

export const DEFAULT_SELECTED_ROLE = {
    label: 'Select Role',
    value: '',
}

export enum USER_ROLE_OBJECT_TYPES {
    ORG = 'org',
    GROUP = 'group',
    PROGRAM = 'prog',
}

export enum FILTER_PAYLOAD_TYPES {
    ORG = 'Org',
    GROUP = 'Group',
    PROGRAM = 'Program',
    PLAN = 'Plan',
    DELIVERABLE = 'Deliverable',
    USER_ALIAS = 'UserAlias',
}

export const DEFAULT_SELECTED_PROGRAM = {
    label: 'Select Program',
    value: '',
}

export const DEFAULT_SELECTED_DELIVERABLE = {
    label: 'Select Deliverable',
    value: '',
}

export const COMMENT_DIV_CLASS = 'comment-drawer-comments'

export const QUILL_TOOLBAR_OPTIONS = [
    [{ header: [3, false] }],
    ['bold', 'italic', 'underline', 'strike', 'link'], // toggled buttons
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    [{ color: [] }, { background: [] }], // allow user to change color or highlight text
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ align: [] }],
    ['clean'], // remove formatting button
]

export interface Comment {
    user_alias: string
    user_role: string
    parent_program_id: string
    deliverable_id: string
    group_id?: string
    org_id?: string
    stl_parent_program_id?: string
    comment_id: string
    text: string
    update_timestamp: number
    deleted?: boolean
}

export interface MUIAutocompleteOption {
    label: string
    type: string
    id: string
}

export interface CommentFilter {
    label: string
    type: string
    id: string
}
