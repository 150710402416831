import Quill from 'quill'
import { MAX_COMMENT_CHARACTOR_COUNT, QUILL_TOOLBAR_OPTIONS } from './Constant'
import { Box, Button, FormField, TextContent } from '@amzn/awsui-components-react'
import { useEffect, useState } from 'react'
import './RichTextCommentInput.scss'
import { useAppContext } from '../../../context'
import { generateUuid } from '../../common/Util'
import { SelectProps } from '@amzn/awsui-components-react/polaris/select/interfaces'
import useStore from '../../Store'

interface RichTextEditorProps {
    refreshComments: () => void
    selectedRole: SelectProps.Option
    selectedProgram: SelectProps.Option
    selectedDeliverable: SelectProps.Option
    selectedPlan: any
}

const RichTextCommentInput = (props: RichTextEditorProps) => {
    const { refreshComments, selectedRole, selectedDeliverable, selectedProgram, selectedPlan } =
        props
    const apiClient = useAppContext().apiClient
    const user = useAppContext().userProps.userAlias ?? 'user'

    const [currentContents, setCurrentContents] = useState<string>('')
    const [characterCount, setCharacterCount] = useState<number>(0)
    const [editor, setEditor] = useState<Quill>()

    const commentEditorDeltaContent = useStore((state) => state.commentEditorDeltaContent)
    const setCommentEditorDeltaContent = useStore((state) => state.setCommentEditorDeltaContent)
    const isCommentCountExceedLimit = characterCount > MAX_COMMENT_CHARACTOR_COUNT

    useEffect(() => {
        const quill = new Quill('#comment-drawer-input', {
            theme: 'snow',
            placeholder: 'Write a comment...',
            modules: {
                toolbar: QUILL_TOOLBAR_OPTIONS,
            },
        })
        quill.on('text-change', () => {
            const content = quill.getContents()
            setCurrentContents(JSON.stringify(content))
            setCharacterCount(quill.getLength() - 1)
            setCommentEditorDeltaContent(content)
        })
        setEditor(quill)
        // Keep last editor's content
        quill.setContents(commentEditorDeltaContent)
    }, [])

    return (
        <FormField
            errorText={isCommentCountExceedLimit && 'Character limit exceeded.'}
            secondaryControl={
                <Box>
                    <Box float={'left'} display={'inline'}>
                        <TextContent>
                            <small>{`Character count: ${characterCount} / ${MAX_COMMENT_CHARACTOR_COUNT}`}</small>
                        </TextContent>
                    </Box>
                    <Box float={'right'} display={'inline'}>
                        <Button
                            variant={'inline-link'}
                            iconName={'send'}
                            onClick={() => {
                                const roleMetadata = JSON.parse(selectedRole.value ?? '')
                                const commentId = generateUuid()
                                const commentPayload = {
                                    user_alias: user,
                                    plan_type: selectedPlan?.plan_type,
                                    plan_year: selectedPlan?.year,
                                    text: currentContents,
                                    comment_id: commentId,
                                    user_role: roleMetadata?.role_id,
                                    group_id: roleMetadata?.group_id,
                                    org_id: roleMetadata?.org_id,
                                    stl_parent_program_id: roleMetadata?.parent_object_id,
                                    deliverable_id: selectedDeliverable.value,
                                    parent_program_id: selectedProgram.value,
                                }
                                apiClient
                                    .post(`/comment/${commentId}`, JSON.stringify(commentPayload))
                                    .then(() => refreshComments())
                                    .catch((err) => {
                                        console.error(err)
                                    })
                                editor?.setContents([])
                            }}
                        ></Button>
                    </Box>
                </Box>
            }
        >
            <div id={'comment-drawer-input'} className={'comment-drawer-input'}></div>
        </FormField>
    )
}

export default RichTextCommentInput
