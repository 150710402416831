// TODO:
//  1. All of this roles, permissions may change based on permission model discussion
//  2. Dynamically load this from persistent storage

export enum ROLES {
    ADMIN = 'Admin', // BOS admin
    STL = 'STL',
    GROUP_MANAGER = 'GroupManager',
    ORG_MANAGER = 'OrgManager',
    UNAUTHORIZED = 'UNAUTHORIZED',
}
export const rolePriority = ['Admin', 'STL', 'Org Manager', 'Group Manager']

export const ALL_ROLES = [
    { id: ROLES.ADMIN, name: 'Admin' },
    { id: ROLES.STL, name: 'STL' },
    { id: ROLES.GROUP_MANAGER, name: 'Group Manager' },
    { id: ROLES.ORG_MANAGER, name: 'Org Manager' },
]

export enum PERMISSIONS {
    CAN_MANAGE_PLAN = 1000,
    CAN_MANAGE_ORG = 1001,
    CAN_MANAGE_PROGRAM = 1002,
    CAN_MANAGE_STL = 1003,
    CAN_MANAGE_LOCK_PLAN = 1004,
    CAN_MANAGE_UNLOCK_PLAN = 1005,
}

export const getPermissions = {
    [ROLES.ADMIN]: [
        PERMISSIONS.CAN_MANAGE_PROGRAM,
        PERMISSIONS.CAN_MANAGE_ORG,
        PERMISSIONS.CAN_MANAGE_PLAN,
        PERMISSIONS.CAN_MANAGE_STL,
        PERMISSIONS.CAN_MANAGE_LOCK_PLAN,
        PERMISSIONS.CAN_MANAGE_UNLOCK_PLAN,
    ],
    [ROLES.UNAUTHORIZED]: [],

    // TODO - add role permission information for all egret roles
}
