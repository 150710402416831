import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react'
import { ModalProps } from '@amzn/awsui-components-react/polaris/modal/interfaces'
import { emptyFn } from './Utils'

interface ModalTemplateProps {
    modalVisible: boolean
    onModalVisibleChange: (visible: boolean) => void
    title: string
    body: JSX.Element
    actionName?: string
    action: () => void
    cancelName?: string
    cancelAction?: () => void
    size?: ModalProps.Size
}

const ModalTemplate = (props: ModalTemplateProps) => {
    const {
        modalVisible,
        onModalVisibleChange,
        title,
        body,
        actionName,
        action,
        cancelName,
        cancelAction = emptyFn,
    } = props
    return (
        <Modal
            onDismiss={() => onModalVisibleChange(false)}
            visible={modalVisible}
            closeAriaLabel='Close modal'
            size={'medium'}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button
                            variant='link'
                            onClick={() => {
                                onModalVisibleChange(false)
                                cancelAction()
                            }}
                        >
                            {cancelName ?? 'Cancel'}
                        </Button>
                        {actionName ? (
                            <Button variant='primary' onClick={() => action()}>
                                {actionName}
                            </Button>
                        ) : (
                            <></>
                        )}
                    </SpaceBetween>
                </Box>
            }
            header={title}
        >
            {body}
        </Modal>
    )
}

export default ModalTemplate
