import dateFormat from 'dateformat'
import {
    ALERT_TYPES,
    COMPONENT_TYPES,
    EMPTY_PLAN_SELECTION,
    EMPTY_REVISION_SELECTION,
} from '../../Constant'

const Diff = require('diff')
import './TextDifferences.scss'
import { Box, SpaceBetween, Badge, TextContent, Toggle } from '@amzn/awsui-components-react'
import { PLAN_REVISION_STATUS_TYPES, STATUS_TO_BADGE_MAP } from '../plan/Constants'
import { AxiosInstance } from 'axios'
import { Dispatch, SetStateAction } from 'react'

export const getMatchesCountText = (count: number | undefined) => {
    return count === 1 ? `1 match` : `${count} matches`
}

export const convertToLocalTime = (utcDateTime) => {
    return utcDateTime ? dateFormat(new Date(utcDateTime), 'yyyy-mm-dd HH:MM:ss') : ''
}

export const allocationInputStep = (value = 1) => {
    return value % 1 == 0 ? 1 : 0.1
}

export const getFloatDecimalValue = (number) => {
    const result =
        number.indexOf('.') >= 0
            ? +number.substr(0, number.indexOf('.')) + number.substr(number.indexOf('.'), 3)
            : +number

    return result.toString()
}

export const getIntValue = (number) => {
    const result =
        number.indexOf('.') >= 0
            ? +number.substr(0, number.indexOf('.')) + number.substr(number.indexOf('.'), 1)
            : +number

    return result.toString()
}

export const getOpEstimateValueSum = (ct, ff) => {
    return Number(parseFloat(ct) + parseFloat(ff)).toFixed(2)
}

export const getInputProperties = (type: COMPONENT_TYPES, value = 1) => {
    switch (type) {
        case COMPONENT_TYPES.INPUT_STRING:
            return { inputMode: 'text', type: 'text', step: undefined }
        case COMPONENT_TYPES.INPUT_INT:
            return { inputMode: 'numeric', type: 'number', step: 1 }
        case COMPONENT_TYPES.INPUT_FLOAT:
            return {
                inputMode: 'decimal',
                type: 'number',
                step: allocationInputStep(value),
            }
        default:
            return { inputMode: 'text', type: 'text', step: undefined }
    }
}

export const mapOptions = (value, options) => {
    return options.find((option) => option.value === value)
}
export const arrayGroupBy = (arr, attr) => {
    const groups: any = {}
    arr.forEach((arrItem) => {
        groups[arrItem[attr]] = [...(groups[arrItem[attr]] || []), arrItem]
    })
    return groups
}

export const objectMap = (obj, mapFun) => {
    // mapFun should be a function of key, value
    const res: any[] = []
    Object.keys(obj).forEach((key) => {
        res.push(mapFun(key, obj[key]))
    })
    return res
}

export const convertToMoneyFormat = (numericInp: any) => {
    const num = Number(String(numericInp))
    if (isNaN(num)) {
        return numericInp
    }
    return num.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    })
}

export const convertToNumberFormat = (numericInp: any) => {
    const num = Number(numericInp)
    if (isNaN(num)) {
        return numericInp
    }

    return num.toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
}

export const getItemSumByKey = (list, key: string) => {
    return list.length > 0
        ? list
              .map((item) => item[key])
              .reduce((prev, next) => (isNaN(prev) ? 0 : +prev) + (isNaN(next) ? 0 : +next))
        : 0
}

export const find_list_diff = (list1, list2) => {
    return list1.reduce((result, element) => {
        if (list2.indexOf(element) === -1) {
            result.push(element)
        }
        return result
    }, [])
}

export const findGroupTeamID = (userGroups, selectedGroup) => {
    const groupInformation = userGroups.find((group) => group.object_id == selectedGroup.object_id)
    if (!groupInformation) {
        return ''
    }
    return groupInformation.hr_permission_group
}

export const filterVisibleColsByTerms = (visibleCols: string[], termList: string[]) => {
    // expects list of column IDs
    const matchStr = termList.join('|')
    return visibleCols
        .filter((colId) => !colId.match(matchStr))
        .flatMap((id) => {
            return {
                id: id,
                visible: true,
            }
        })
}

export const getLeadingNumZeros = (index: number, strLength: number) => {
    // returns str with leading zeros
    const formattedNum = String(index)
    const numZeroes = strLength - formattedNum.length
    if (numZeroes <= 0) {
        return formattedNum
    }
    return '0'.repeat(numZeroes) + formattedNum
}

export const findTextDiff = (prev: string, next: string) => {
    if (!prev || !next) {
        return prev || next
    }

    const result: any = []
    const diff = Diff.diffWords(prev, next)
    diff.forEach((part) => {
        if (part.added) {
            result.push(<mark>{part.value}</mark>)
        } else if (!part.removed) {
            result.push(part.value)
        }
    })

    return result.length ? result : '-'
}

export const getProgramNameWithoutActiveStatus = (programOption) => {
    // Example of programOption = { label: "Falcon - active", value: "program_id" };
    // Expected return result "Falcon"
    const selectCopyProgramNameStrings = programOption.label.split('-')
    selectCopyProgramNameStrings.pop()
    return selectCopyProgramNameStrings.join('')
}

export const getAllProgramRevisionOptionsForPlan = (
    clientObj,
    programId,
    revisionId,
    planId,
    setSelectedRevision,
    setRevisionOptions,
    alertHandler,
) => {
    clientObj
        .get(`/program/${programId}/revisions`)
        .then((res) => {
            const allRevisions = res.data || []
            const planRevisions = allRevisions.filter((rev) => rev.plan_id === planId)
            if (planRevisions.length) {
                const formattedRevisions = planRevisions.map((rev: any) => {
                    return formatRevisionOptions(rev)
                })
                const selectedRevision =
                    formattedRevisions.find((revOption) => revOption.value === revisionId) ||
                    EMPTY_REVISION_SELECTION
                setSelectedRevision(selectedRevision)
                setRevisionOptions(formattedRevisions)
            }
        })
        .catch((error) => {
            console.error(error)
            alertHandler(
                `Unable to retrieve revisions metadata for program id ${programId}.`,
                ALERT_TYPES.ERROR,
            )
        })
}

export const formatPlanOptions = (plan) => {
    if (!plan) {
        return EMPTY_PLAN_SELECTION
    }

    return {
        value: plan,
        label: (
            <TextContent>
                <p>
                    {`${plan?.plan_type || ''} ${plan?.year || ''}`}{' '}
                    <Badge
                        color={STATUS_TO_BADGE_MAP[plan.plan_status] || 'blue'}
                    >{`${PLAN_REVISION_STATUS_TYPES[plan?.plan_status] || ''}`}</Badge>
                </p>
            </TextContent>
        ),
    }
}

// Temporary while formatPlanOptions is still in use
export const formatPlanOptionsWithData = (plan) => {
    if (!plan) {
        return EMPTY_PLAN_SELECTION
    }

    return {
        value: plan.plan_id,
        label: (
            <TextContent>
                <p>
                    {`${plan?.plan_type || ''} ${plan?.year || ''}`}{' '}
                    <Badge
                        color={STATUS_TO_BADGE_MAP[plan.plan_status] || 'blue'}
                    >{`${PLAN_REVISION_STATUS_TYPES[plan?.plan_status] || ''}`}</Badge>
                </p>
            </TextContent>
        ),
        data: plan,
    }
}

export const formatRevisionOptions = (rev) => {
    if (!rev) {
        return EMPTY_REVISION_SELECTION
    }

    return {
        label: (
            <TextContent>
                <p>
                    {`Revision ${rev.revision_number} - ${rev.revision_title}`}{' '}
                    <Badge
                        color={STATUS_TO_BADGE_MAP[rev.revision_status]}
                    >{`${PLAN_REVISION_STATUS_TYPES[rev.revision_status]}`}</Badge>
                </p>
            </TextContent>
        ),
        value: rev.revision_id,
    }
}

export const getMetadataForPlan = (apiClient, planId, setPlanMetadata, setSelectedPlan) => {
    apiClient
        .get(`/plan/${planId}`)
        .then((res) => {
            const planMeta = res.data
            setPlanMetadata(planMeta)
            setSelectedPlan(formatPlanOptions(planMeta))
        })
        .catch((err) => {
            console.error(err)
        })
}

export const getMetadataForRevision = (apiClient, planId, revisionId, setRevisionMetadata) => {
    apiClient
        .get(`plan/${planId}/revision?revision_id=${revisionId}`)
        .then((res) => {
            setRevisionMetadata(res.data)
        })
        .catch((err) => {
            console.error(err)
            setRevisionMetadata({})
        })
}

export const generateToggleComponent = (leftText, rightText, checkedValue, setValue) => {
    return (
        <SpaceBetween size={'xs'} direction={'horizontal'}>
            <Box padding={{ top: 'xs' }}>
                <TextContent>
                    <p style={{ fontWeight: checkedValue ? 'normal' : 'bold' }}> {leftText} </p>
                </TextContent>
            </Box>
            <Box padding={{ top: 's' }}>
                <Toggle
                    id={'toggle'}
                    checked={checkedValue}
                    onChange={({ detail }) => {
                        setValue(detail.checked)
                    }}
                />
            </Box>
            <Box padding={{ top: 'xs' }}>
                <TextContent>
                    <p
                        style={{
                            fontWeight: checkedValue ? 'bold' : 'normal',
                        }}
                    >
                        {' '}
                        {rightText}{' '}
                    </p>
                </TextContent>
            </Box>
        </SpaceBetween>
    )
}

export const emptyFn = () => null

export const getSortingValue = (item, attr) => {
    return !(attr in item) || item[attr] === '' ? Number.MAX_VALUE : +item[attr]
}

export const getItemsApi = (
    apiClient: AxiosInstance,
    url: string,
    onItemsChange: Dispatch<SetStateAction<any>>,
    dataFormatter = (data) => data,
) => {
    apiClient
        .get(url)
        .then((res) => {
            onItemsChange(dataFormatter(res.data))
        })
        .catch((error) => {
            console.error(error)
        })
}
