import { useEffect, useState } from 'react'
import {
    Box,
    Grid,
    Link,
    Multiselect,
    Select,
    SpaceBetween,
    TopNavigation,
} from '@amzn/awsui-components-react'
import { useAppContext } from '../../context'
import { useAuth } from '../../auth/EgretAuthProvider'
import { useNavigate, useLocation } from 'react-router-dom'
import { TopNavigationProps } from '@amzn/awsui-components-react/polaris/top-navigation/interfaces'
import _ from 'lodash'

import {
    EMAIL_TO_TEAM,
    EGRET_SLACK_CHANNEL,
    FEATURE_REQUEST_LINK,
    REPORT_BUG_LINK,
    REQUEST_ACCESS_LINK,
} from '../common/LinkUtil'
import useStore from '../Store'
import {
    NO_USER_BUSINESS_ENTITY,
    PLAN,
    REVISION,
    ORG,
    USER_GUIDE_LINK,
    VIDEO_TUTORIALS_LINK,
} from '../Constant'
import BusinessEntityRefresh from '../apps/reusable/BusinessEntityRefresh'
import ModalTemplate from '../apps/reusable/ModalTemplate'
import { rolePriority, ROLES } from '../../role'
import { getHolidaySession } from '../common/Util'
import { formatPlanOptionsWithData } from '../apps/reusable/Utils'
import { sortPlanOptionsWithData } from '../apps/program/ProgramSharedUtils'
import { USER_ROLES } from '../apps/comment/Constant'

interface NavBarProps {
    setBusinessEntityBannerContent: any
    initialBusinessEntity: any
}

const Navbar = (props: NavBarProps) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userProps = appContext.userProps
    const navigate = useNavigate()
    const location = useLocation()
    const paths = location.pathname.split('/')

    const businessEntities = useStore((state) => state.businessEntities)
    const { userRoles, canAdmin, canScopeProgramsDeliverables, canEditPrograms, isAuthChecked } =
        useAuth()
    const setSelectedBusinessEntity = useStore((state) => state.setSelectedBusinessEntity)

    const [globalSelectedBE, setGlobalSelectedBE] = useState(props.initialBusinessEntity)
    const [beChanged, setBEChanged] = useState(false)
    const [clickedBEName, setClickedBEName] = useState(
        props.initialBusinessEntity.business_entity_name,
    )
    const [personaModalVisible, setPersonaModalVisible] = useState(false)
    const [selectedPersonaRole, setSelectedPersonaRole] = useState<any>([])
    const [selectedPersonaGroups, setSelectedPersonaGroups] = useState<any>([])
    const [selectedPersonaOrgs, setSelectedPersonaOrgs] = useState<any>([])
    const [selectedPersonaPrograms, setSelectedPersonaPrograms] = useState<any>([])
    const [planOptions, setPlanOptions] = useState<any>([])
    const [selectedPlan, setSelectedPlan] = useState<any>({})
    const [canAccessBE, setCanAccessBE] = useState(true)
    const [logoUrl, setLogoUrl] = useState<string>('')

    const userPermissionRoles = useStore((state) => state.userRoles)
    const setUserRoles = useStore((state) => state.setUserRoles)
    const setUserRolesMetadata = useStore((state) => state.setUserRolesMetadata)
    const setUserGroups = useStore((state) => state.setUserGroups)
    const setUserOrgs = useStore((state) => state.setUserOrgs)
    const setCanAdmin = useStore((state) => state.setCanAdmin)
    const setCanScopeProgramsDeliverables = useStore(
        (state) => state.setCanScopeProgramsDeliverables,
    )
    const setCanEditPrograms = useStore((state) => state.setCanEditPrograms)
    const setUserPrograms = useStore((state) => state.setUserPrograms)
    const currentBEUserRole = userRoles[globalSelectedBE.business_entity_id]

    const updateUserProperties = async (
        roles,
        userRolesMetadata,
        userOrgs,
        userPrograms,
        userGroups,
        canAdmin,
        canScopeDeliverables,
        canEditPrograms,
    ) => {
        setUserRoles(roles)
        setUserRolesMetadata(userRolesMetadata)
        setUserOrgs(userOrgs)
        setUserPrograms(userPrograms)
        setUserGroups(userGroups)
        setCanAdmin(canAdmin)
        setCanScopeProgramsDeliverables(canScopeDeliverables)
        setCanEditPrograms(canEditPrograms)
    }

    useEffect(() => {
        // initialize user BE
        setGlobalSelectedBE(props.initialBusinessEntity)
        setClickedBEName(props.initialBusinessEntity.business_entity_name)
    }, [props.initialBusinessEntity])

    useEffect(() => {
        if (
            !globalSelectedBE ||
            !globalSelectedBE.business_entity_id ||
            globalSelectedBE.business_entity_id === NO_USER_BUSINESS_ENTITY
        ) {
            return
        }
        const businessEntityId = globalSelectedBE.business_entity_id
        if (
            businessEntityId === NO_USER_BUSINESS_ENTITY ||
            !businessEntityId ||
            !isAuthChecked ||
            userRoles === undefined
        ) {
            return
        }

        const businessEntityRoleData = userRoles[businessEntityId]
        if (
            (isAuthChecked && !Object.keys(userRoles).length) ||
            businessEntityRoleData === undefined
        ) {
            return
        }
        BusinessEntityRefresh(globalSelectedBE.business_entity_id, () => {
            updateUserProperties(
                businessEntityRoleData['ROLES'] || [],
                businessEntityRoleData || {},
                businessEntityRoleData['USER_ORGS'] || [],
                businessEntityRoleData['USER_PROGRAMS'] || [],
                businessEntityRoleData['USER_GROUPS'] || [],
                canAdmin[businessEntityId] || false,
                canScopeProgramsDeliverables[businessEntityId] || false,
                canEditPrograms[businessEntityId] || false,
            )
        })
        getAllPlans()
    }, [globalSelectedBE, userRoles])

    useEffect(() => {
        if (!canAccessBE) {
            props.setBusinessEntityBannerContent({
                header: 'Insufficient Permissions',
                type: 'error',
                content: (
                    <>
                        You do not have access to the {`${clickedBEName}`} business entity. Please
                        open a{' '}
                        <Link color={'inverted'} href={REQUEST_ACCESS_LINK}>
                            {' '}
                            ticket{' '}
                        </Link>
                        to request access.
                    </>
                ),
            })
        } else if (globalSelectedBE.business_entity_id) {
            // make sure we completed checking for user BE before setting content
            const userHasBE = globalSelectedBE.business_entity_id !== NO_USER_BUSINESS_ENTITY
            if (!beChanged && userHasBE) {
                return
            }
            props.setBusinessEntityBannerContent({
                header: !userHasBE ? 'Missing Business Entity' : beChanged ? 'Success' : '',
                type: !userHasBE ? 'warning' : beChanged ? 'success' : '',
                content: !userHasBE
                    ? `Please select a business entity from the top navigation bar.`
                    : beChanged
                      ? `Successfully switched to ${clickedBEName}`
                      : '',
            })
        } else {
            props.setBusinessEntityBannerContent({
                header: '',
                type: '',
                content: '',
            })
        }
    }, [canAccessBE, globalSelectedBE])

    const getAllPlans = () => {
        apiClient
            .get(`plan/business-entity/${globalSelectedBE.business_entity_id}?year=`)
            .then((response) => {
                const allPlans = response.data.map((plan) => ({
                    ...plan,
                    ['plan_name']: `${plan['year'] || ''} ${plan['plan_type'] || ''}`,
                    ['business_entity_id']: globalSelectedBE.business_entity_id,
                }))
                const planOptions = sortPlanOptionsWithData(
                    allPlans.map((plan) => formatPlanOptionsWithData(plan)),
                )
                setPlanOptions(planOptions)
                setSelectedPlan(planOptions[0])
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const handleClickMenuButton = (e) => {
        if (e.detail.id === 'sync_permissions') {
            apiClient
                .delete(`/falcon/user/${userProps.userAlias}/caches`)
                .then(() => {
                    window.location.reload()
                })
                .catch((error) => {
                    console.error(error)
                })
            return
        } else if (e.detail.id === 'switch_persona') {
            setPersonaModalVisible(true)
            getAllPlans()
        } else if (e.detail.id === 'reset_permissions') {
            window.location.reload()
        }

        if (
            e.detail.id === 'enhancement_request' ||
            e.detail.id === 'report_bug' ||
            e.detail.id === 'email_us' ||
            e.detail.id === 'join_slack' ||
            e.detail.id === 'user_guide' ||
            e.detail.id === 'video_tutorial' ||
            e.detail.id === 'request_access'
        ) {
            return
        }

        e.preventDefault()
        if (e.detail['href'] === undefined) {
            navigate('/')
            return
        }
        navigate(e.detail.href)
    }

    const helpMenu = {
        id: 'help',
        text: 'Help & Request',
        items: [
            {
                id: 'enhancement_request',
                iconName: 'ticket',
                text: 'Enhancement Request',
                href: FEATURE_REQUEST_LINK,
                external: true,
            },
            {
                id: 'report_bug',
                iconName: 'bug',
                text: 'Report a Bug',
                href: REPORT_BUG_LINK,
                external: true,
            },
            {
                id: 'request_access',
                iconName: 'lock-private',
                text: 'Request Access',
                href: REQUEST_ACCESS_LINK,
                external: true,
            },
            {
                id: 'email_us',
                iconName: 'envelope',
                text: 'Email Us',
                href: EMAIL_TO_TEAM,
                external: true,
            },
            {
                id: 'join_slack',
                iconName: 'contact',
                text: 'Join Slack channel',
                href: EGRET_SLACK_CHANNEL,
                external: true,
            },
        ],
    }

    const learnMenu = {
        id: 'learn_more',
        text: 'Learn more',
        items: [
            {
                id: 'user_guide',
                iconName: 'file',
                text: 'User Guide',
                href: USER_GUIDE_LINK,
                external: true,
            },
            {
                id: 'video_tutorial',
                iconName: 'video-on',
                text: 'Video Tutorials',
                href: VIDEO_TUTORIALS_LINK,
                external: true,
            },
        ],
    }

    const getBusinessEntities = () => {
        return businessEntities.map((businessEntity) => ({
            id: businessEntity.business_entity_id,
            text: businessEntity.business_entity_name,
        }))
    }

    const handleSelectBE = (e) => {
        if (paths.length >= 5 && paths[1] === PLAN && paths[3] === REVISION) {
            navigate('/programs')
        } else if (paths.length && paths[1] === ORG) {
            navigate('/orgs')
        }
        const selectedBEId = e.detail.id
        const selected = businessEntities.filter(
            (businessEntity) => businessEntity.business_entity_id === selectedBEId,
        )[0]
        setClickedBEName(selected.business_entity_name)
        const sameBE = globalSelectedBE.business_entity_id === selectedBEId
        setBEChanged(!sameBE)
        if (sameBE) {
            return
        }
        // check if the user has access to the selected BE before switching
        apiClient
            .get(`/falcon/user-business-entity/${selectedBEId}/has-auth`)
            .then((response) => {
                const canAccess = response.data
                setCanAccessBE(canAccess)
                if (!canAccess) {
                    return
                }
                setSelectedBusinessEntity(selected)
                setGlobalSelectedBE(selected)
            })
            .catch((error) => {
                console.error(error)
                setCanAccessBE(false)
                return
            })
        const payload = {
            user_alias: userProps.userAlias,
            business_entity_id: selectedBEId,
        }
        apiClient.post('/falcon/user-business-entity', JSON.stringify(payload)).catch((err) => {
            console.error(err)
        })
    }

    const businessEntityMenu: TopNavigationProps.Utility[] = [
        {
            type: 'menu-dropdown',
            text: globalSelectedBE.business_entity_name,
            onItemClick: handleSelectBE,
            items: getBusinessEntities(),
        },
    ]

    const menu: TopNavigationProps.Utility[] = [
        {
            type: 'menu-dropdown',
            text: `Hello, ${userProps ? userProps.userAlias : ''}`,
            onItemClick: handleClickMenuButton,
            items: [
                {
                    id: 'sync_permissions',
                    iconName: 'refresh',
                    text: 'Sync Permissions',
                },
                {
                    id: 'switch_persona',
                    iconName: 'user-profile',
                    text: 'Switch Persona',
                    disabled: !currentBEUserRole || !globalSelectedBE,
                },
                {
                    id: 'reset_permissions',
                    iconName: 'refresh',
                    text: 'Reset Persona',
                },
                helpMenu,
                learnMenu,
            ],
        },
    ]

    const getHighestPriorityUserRole = () => {
        const roles = userPermissionRoles
            ? userPermissionRoles.flatMap((role) => role.role_name)
            : []
        return rolePriority.find((role) => roles.includes(role))
    }

    const selectionSort = (object1, object2) => {
        return object1?.object_name.localeCompare(object2?.object_name)
    }

    const isAssociatedRoleSelected = (associatedRole) => {
        return selectedPersonaRole.map((role) => role.label).includes(associatedRole)
    }

    const generateDefaultSelection = (entity) => {
        switch (entity) {
            case 'Org':
                return currentBEUserRole?.USER_ORGS?.sort(selectionSort).flatMap((org) => {
                    return {
                        value: org.object_id,
                        label: org.object_name,
                    }
                })
            case 'Group':
                return currentBEUserRole?.USER_GROUPS?.filter(
                    (group) =>
                        group.is_egret &&
                        group.business_entity_id === globalSelectedBE.business_entity_id &&
                        group.is_active,
                )
                    .sort((group1, group2) => group1?.group_name.localeCompare(group2?.group_name))
                    .flatMap((group) => {
                        return {
                            value: group.group_id,
                            label: group.group_name,
                        }
                    })
            case 'Role':
                const currentRole = getHighestPriorityUserRole()
                return currentBEUserRole?.ROLES?.filter((role) => role.role_name !== currentRole)
                    .sort((role1, role2) => role1?.role_name.localeCompare(role2?.role_name))
                    .flatMap((role) => {
                        return {
                            value: role.id.toLowerCase(),
                            label: role.role_name,
                        }
                    })
            case 'Program':
                // add plan information to selected programs in order for users to know if certain programs are extended across plans
                const programPlanMap = {}
                currentBEUserRole?.USER_PROGRAMS.forEach((prog) => {
                    const name = prog.object_name
                    const plan = prog.object_id.split('#')[0]
                    const planMetadata = planOptions.find((option) => option.value === plan).data
                    if (!Object.keys(programPlanMap).includes(name)) {
                        programPlanMap[name] = []
                    }
                    programPlanMap[name].push(planMetadata.plan_name)
                })
                return currentBEUserRole?.USER_PROGRAMS?.sort(selectionSort)
                    .filter(
                        (prog) =>
                            prog.object_id !== '' &&
                            prog.object_id.split('#')[0] === selectedPlan?.value,
                    )
                    .flatMap((program) => {
                        const name = program.object_name
                        return {
                            value: program.object_id,
                            label: `${name} - [${programPlanMap[name].join(', ')}]`,
                        }
                    })
        }
    }

    const clearSwitchPersonaSelections = () => {
        setSelectedPersonaRole([])
        setSelectedPersonaGroups([])
        setSelectedPersonaOrgs([])
        setSelectedPersonaPrograms([])
    }

    const retrieveSelectedObjects = (entityList, selectedEntityList) => {
        const selectedEntityNames = selectedEntityList.flatMap((entity) => entity.label)
        const detailedEntityList: any[] = []
        entityList.forEach((entity) => {
            const keys = Object.keys(entity)
            const entityName = keys.includes('object_name')
                ? entity.object_name
                : keys.includes('role_name')
                  ? entity.role_name
                  : entity.group_name
            if (selectedEntityNames.includes(entityName)) {
                detailedEntityList.push(entity)
            }
        })
        return detailedEntityList
    }

    const switchUserRoles = () => {
        const temporaryPermissions = {}
        const temporaryRoles = retrieveSelectedObjects(currentBEUserRole.ROLES, selectedPersonaRole)
        let temporaryCanAdmin = false
        let temporaryCanEditPrograms = false
        let temporaryCanScopeProgramsDeliverables = false

        temporaryPermissions['ROLES'] = temporaryRoles
        const roleID = temporaryRoles.flatMap((role) => role.id)

        if (roleID.includes(ROLES.GROUP_MANAGER)) {
            const tempGroups = retrieveSelectedObjects(
                currentBEUserRole.USER_GROUPS,
                selectedPersonaGroups,
            )
            temporaryPermissions['USER_GROUPS'] = tempGroups
            temporaryPermissions['USER_REGISTERED_GROUPS'] = tempGroups
            temporaryCanScopeProgramsDeliverables = true
        } else {
            temporaryPermissions['USER_GROUPS'] = []
        }
        const groups = temporaryPermissions['USER_GROUPS'].flatMap((group) => group.group_name)
        if (roleID.includes(ROLES.ORG_MANAGER)) {
            // add groups under the org
            temporaryPermissions['USER_ORGS'] = retrieveSelectedObjects(
                currentBEUserRole.USER_ORGS,
                selectedPersonaOrgs,
            )
            const orgs = temporaryPermissions['USER_ORGS'].flatMap((org) => org.object_name)
            temporaryPermissions['USER_GROUPS'] = [
                ...temporaryPermissions['USER_GROUPS'],
                ...currentBEUserRole.USER_GROUPS.filter(
                    (group) => orgs.includes(group.org_name) && !groups.includes(group.group_name),
                ),
            ]
            temporaryCanScopeProgramsDeliverables = true
        } else {
            temporaryPermissions['USER_ORGS'] = []
        }
        if (roleID.includes(ROLES.STL)) {
            temporaryPermissions['USER_PROGRAMS'] = retrieveSelectedObjects(
                currentBEUserRole.USER_PROGRAMS,
                selectedPersonaPrograms,
            )
            temporaryCanEditPrograms = true
        } else {
            temporaryPermissions['USER_PROGRAMS'] = []
        }
        if (roleID.includes(ROLES.ADMIN)) {
            temporaryPermissions['USER_ORGS'] = selectedPersonaOrgs.length
                ? retrieveSelectedObjects(currentBEUserRole.USER_ORGS, selectedPersonaOrgs)
                : currentBEUserRole.USER_ORGS
            temporaryPermissions['USER_GROUPS'] = selectedPersonaGroups.length
                ? retrieveSelectedObjects(currentBEUserRole.USER_GROUPS, selectedPersonaGroups)
                : currentBEUserRole.USER_GROUPS
            temporaryPermissions['USER_PROGRAMS'] = selectedPersonaPrograms.length
                ? retrieveSelectedObjects(currentBEUserRole.USER_PROGRAMS, selectedPersonaPrograms)
                : currentBEUserRole.USER_PROGRAMS
            temporaryCanAdmin = true
        }

        // set new temporary user roles
        updateUserProperties(
            temporaryPermissions['ROLES'],
            temporaryPermissions,
            temporaryPermissions['USER_ORGS'],
            temporaryPermissions['USER_PROGRAMS'],
            temporaryPermissions['USER_GROUPS'],
            temporaryCanAdmin,
            temporaryCanScopeProgramsDeliverables,
            temporaryCanEditPrograms,
        )

        setPersonaModalVisible(false)
    }

    const getLogos = () => {
        apiClient
            .get(`/settings?keys=logo`)
            .then((res) => {
                const logoSettings = res.data
                const logos = logoSettings.filter((logoSetting) =>
                    logoSetting.value.startsWith(getHolidaySession()),
                )
                const randomLogo = logos[Math.floor(Math.random() * logos.length)]
                setLogoUrl(randomLogo.url)
            })
            .catch((err) => {
                console.error(err)
            })
    }

    useEffect(() => {
        getLogos()
    }, [])

    return (
        <Box>
            <TopNavigation
                data-cy={'top nav bar'}
                identity={{
                    href: '/',
                    title: 'Egret - OP Management',
                    logo: logoUrl
                        ? {
                              src: logoUrl,
                              alt: 'Egret Logo',
                          }
                        : undefined,
                    onFollow: handleClickMenuButton,
                }}
                utilities={[...businessEntityMenu, ...menu]}
                i18nStrings={{
                    searchIconAriaLabel: 'Search',
                    searchDismissIconAriaLabel: 'Close search',
                    overflowMenuTriggerText: 'More',
                    overflowMenuTitleText: 'All',
                    overflowMenuBackIconAriaLabel: 'Back',
                    overflowMenuDismissIconAriaLabel: 'Close menu',
                }}
            />
            <ModalTemplate
                modalVisible={personaModalVisible}
                onModalVisibleChange={setPersonaModalVisible}
                title='Switch Persona'
                size={'large'}
                body={
                    <SpaceBetween size={'s'} direction={'vertical'}>
                        <Grid gridDefinition={[{ colspan: 4 }, { colspan: 6 }, { colspan: 2 }]}>
                            <Box variant={'p'} textAlign={'left'}>
                                {' '}
                                Current Role:{' '}
                            </Box>
                            <Box variant={'p'} textAlign={'left'}>
                                {' '}
                                {getHighestPriorityUserRole()}{' '}
                            </Box>
                            <></>
                        </Grid>
                        <Grid gridDefinition={[{ colspan: 4 }, { colspan: 6 }, { colspan: 2 }]}>
                            <Box variant={'p'} textAlign={'left'}>
                                Target Role(s):
                            </Box>
                            <Multiselect
                                selectedOptions={selectedPersonaRole}
                                onChange={({ detail }) => {
                                    setSelectedPersonaRole(detail.selectedOptions)
                                }}
                                options={generateDefaultSelection('Role')}
                                placeholder={'Select Roles'}
                            ></Multiselect>
                            <></>
                        </Grid>
                        {isAssociatedRoleSelected(USER_ROLES.USER_PROGRAMS) && (
                            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 6 }, { colspan: 2 }]}>
                                <Box variant={'p'} textAlign={'left'}>
                                    Select Plan:
                                </Box>
                                <Select
                                    selectedOption={selectedPlan}
                                    onChange={({ detail }) => {
                                        setSelectedPlan(detail.selectedOption)
                                    }}
                                    options={planOptions}
                                    placeholder={'Select Plan'}
                                ></Select>
                                <></>
                            </Grid>
                        )}
                        {!_.isEmpty(currentBEUserRole?.USER_GROUPS) &&
                            isAssociatedRoleSelected(USER_ROLES.USER_REGISTERED_GROUPS) && (
                                <Grid
                                    gridDefinition={[
                                        { colspan: 4 },
                                        { colspan: 6 },
                                        { colspan: 2 },
                                    ]}
                                >
                                    <Box variant={'p'} textAlign={'left'}>
                                        User Groups:
                                    </Box>
                                    <Multiselect
                                        selectedOptions={selectedPersonaGroups}
                                        onChange={({ detail }) => {
                                            setSelectedPersonaGroups(detail.selectedOptions)
                                        }}
                                        options={generateDefaultSelection('Group')}
                                        placeholder={'Select Groups'}
                                    ></Multiselect>
                                    <></>
                                </Grid>
                            )}
                        {!_.isEmpty(currentBEUserRole?.USER_ORGS) &&
                            isAssociatedRoleSelected(USER_ROLES.USER_ORGS) && (
                                <Grid
                                    gridDefinition={[
                                        { colspan: 4 },
                                        { colspan: 6 },
                                        { colspan: 2 },
                                    ]}
                                >
                                    <Box variant={'p'} textAlign={'left'}>
                                        User Orgs:
                                    </Box>
                                    <Multiselect
                                        selectedOptions={selectedPersonaOrgs}
                                        onChange={({ detail }) => {
                                            setSelectedPersonaOrgs(detail.selectedOptions)
                                        }}
                                        options={generateDefaultSelection('Org')}
                                        placeholder={'Select Orgs'}
                                    ></Multiselect>
                                    <></>
                                </Grid>
                            )}
                        {!_.isEmpty(currentBEUserRole?.USER_PROGRAMS) &&
                            isAssociatedRoleSelected(USER_ROLES.USER_PROGRAMS) && (
                                <Grid
                                    gridDefinition={[
                                        { colspan: 4 },
                                        { colspan: 6 },
                                        { colspan: 2 },
                                    ]}
                                >
                                    <Box variant={'p'} textAlign={'left'}>
                                        User Programs:
                                    </Box>
                                    {!_.isEmpty(generateDefaultSelection('Program')) ? (
                                        <Multiselect
                                            selectedOptions={generateDefaultSelection('Program')}
                                            options={generateDefaultSelection('Program')}
                                            placeholder={'Selected STL Programs'}
                                            readOnly
                                        ></Multiselect>
                                    ) : (
                                        <Box variant={'p'} textAlign={'left'}>
                                            You are not registered as an STL for any of the programs
                                            in the selected Plan. Please add yourself as an
                                            STL/registered user for a program before switching
                                            persona.
                                        </Box>
                                    )}
                                    <></>
                                </Grid>
                            )}
                    </SpaceBetween>
                }
                actionName={'Submit'}
                action={switchUserRoles}
                cancelAction={clearSwitchPersonaSelections}
            />
        </Box>
    )
}

export default Navbar
