import {
    AppLayout,
    Box,
    Button,
    ButtonDropdown,
    Container,
    ContentLayout,
    Header,
    Icon,
    Select,
    SpaceBetween,
    Tabs,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { ACTION_TYPES, useNewSummaryData } from './useNewSummaryData'
import { formatRevisionOptions, generateToggleComponent, getItemsApi } from '../reusable/Utils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PLAN, REVISION, SUMMARY_TABS, SUMMARY_TABS_OPTIONS } from '../../Constant'

import { ClientSideRowModelModule, InfiniteRowModelModule, ModuleRegistry } from 'ag-grid-community'
import {
    ExcelExportModule,
    LicenseManager,
    RowGroupingModule,
    ServerSideRowModelModule,
    SetFilterModule,
} from '@amzn/ag-bird/src/ag-grid-enterprise'
import './SummaryView.scss'
import { agGridLicense } from '@amzn/ag-bird/src/ag-grid-license/ag-grid-license'
import {
    formatSelection,
    getSummaryGetContextMenuItemsGridOption,
    GRAND_TOTAL_COLOR_CODE,
    NORMAL_CELL_COLOR_CODE,
} from './SummaryUtil'
import { AgGridReact } from 'ag-grid-react'
import Preferences from './Preferences'
import { useQuery, useQueryState } from '../../UseQuery'
import {
    COL_LIST,
    COLUMNS,
    parseArrayFromString,
    PROGRAM,
    ROW_LIST,
    ROWS,
    setOrgByGroupList,
    setOrgList,
    TAB,
} from './PreferencesUtil'
import {
    isLoading,
    nameComparator,
    priorityComparator,
    ProgramTableColumns,
    ROW_HEIGHT,
    setRowsAndColumns,
    SORT_OPTIONS,
    getPreferenceData,
} from './NewSummariesUtil'
import './SummariesAudits.scss'
import HCDetailModal from '../program/deliverables/HCDetailModal'
import { SUMMARIES_SUB_DESC } from '../home/HomePageConstants'
import ProgramModal from './ProgramModal'
import { generateToolSideBar, SPEND_HIDDEN_FIELD_IDS } from './NewSpendSummaryUtils'
import CommentIcon from '../comment/CommentIcon'
import {
    COMMENT_PANEL_MODE,
    DEFAULT_SELECTED_DELIVERABLE,
    DEFAULT_SELECTED_PROGRAM,
    DEFAULT_SELECTED_ROLE,
    FILTER_PAYLOAD_TYPES,
    MUIAutocompleteOption,
    CommentFilter,
} from '../comment/Constant'
import CommentPanel from '../comment/CommentPanel'
import {
    DEFAULT_COL_DEFS,
    generateCellStyle,
    getProgramPriorityFromGroup,
} from '../program/SandboxUtils'
import useStore from '../../Store'
import { HEADER_BG_COLOR } from '../plan/Constants'
import { useAppContext } from '../../../context'
import { decompressResponse } from '../../common/Util'
import { SelectProps } from '@amzn/awsui-components-react/polaris/select/interfaces'
import _ from 'lodash'
import {
    formatCommentPanelSearchOptions,
    generateFilterPayload,
    generateFixedInitialPlanCommentFilter,
    generateInitialCommentFilters,
    getUserRoleSelections,
} from '../comment/CommentUtil'
import PriorityBadgeCellRenderer from './PriorityBadgeCellRenderer'

LicenseManager.setLicenseKey(agGridLicense)
ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ServerSideRowModelModule,
    ExcelExportModule,
    SetFilterModule,
    InfiniteRowModelModule,
    RowGroupingModule,
])

const SummariesAudits = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const { data, dispatch } = useNewSummaryData()

    const query = useQuery()
    const initialTab = query.get(TAB) || SUMMARY_TABS.PROGRAM

    const [selectedTab, setSelectedTab] = useState<SUMMARY_TABS>(initialTab as SUMMARY_TABS)
    const [isPreferencesVisible, setPreferencesVisible] = useState(false)
    const openPreferencesModal = () => setPreferencesVisible(true)
    const closePreferencesModal = () => setPreferencesVisible(false)
    const [orgs, setOrgs] = useState<{ id: string; label: string }[]>([])
    const [groups, setGroups] = useState<{ id: string; label: string; children: any[] }[]>([])
    const url = window.location.href
    const initialOrgs = useMemo(
        () =>
            parseArrayFromString(
                query.get(SUMMARY_TABS.ORG),
                initialTab === SUMMARY_TABS.PROGRAM
                    ? orgs?.map((org) => org.label)
                    : [orgs[0]?.label],
            ),
        [url, orgs],
    )

    const initialGroups = useMemo(
        () => parseArrayFromString(query.get(SUMMARY_TABS.GROUP), [groups[0]?.children[0].value]),
        [url, groups],
    )
    const initialRows = useMemo(() => parseArrayFromString(query.get(ROWS), ROW_LIST), [url])
    const initialColumns = useMemo(() => parseArrayFromString(query.get(COLUMNS), COL_LIST), [url])
    const [programModalVisible, setProgramModalVisible] = useState<boolean>(false)

    // comment side panel state
    const [isSidePanelVisible, setIsSidePanelVisible] = useState<boolean>(false)
    const [commentMode, setCommentMode] = useState<COMMENT_PANEL_MODE>(COMMENT_PANEL_MODE.DISABLED)
    const [comments, setComments] = useState<any[]>([])
    const [pageIdx, setPageIdx] = useState(0)
    const [allOrgs, setAllOrgs] = useState<any[]>([])
    const [allGroups, setAllGroups] = useState<any[]>([])
    const [selectedRole, setSelectedRole] = useState<SelectProps.Option>(DEFAULT_SELECTED_ROLE)
    const [selectedProgram, setSelectedProgram] =
        useState<SelectProps.Option>(DEFAULT_SELECTED_PROGRAM)
    const [commentDeliverableOptions, setCommentDeliverableOptions] = useState<
        SelectProps.Option[]
    >([])
    const [selectedDeliverable, setSelectedDeliverable] = useState<SelectProps.Option>(
        DEFAULT_SELECTED_DELIVERABLE,
    )
    const [parentProgramGlobalAttributes, setParentProgramGlobalAttributes] = useState<any[]>([])
    const userRolesMetadata = useStore((state) => state.userRolesMetadata)
    const userRoleSelections = getUserRoleSelections(
        userRolesMetadata,
        data[ACTION_TYPES.PLAN].selected.data,
    )
    const [selectedFilters, setSelectedFilters] = useState<CommentFilter[]>(
        generateInitialCommentFilters(userRoleSelections, data[ACTION_TYPES.PLAN].selected.data),
    )
    const [filterOptions, setFilterOptions] = useState<CommentFilter[]>([])
    const [allLocalProgramMap, setAllLocalProgramMap] = useState<any>({})
    const [programNameMap, setProgramNameMap] = useState<any>({})
    useEffect(() => {
        if (!data.gridApi) {
            return
        }

        if (isLoading(data)) {
            data.gridApi.showLoadingOverlay()
        } else {
            data.gridApi.hideOverlay()
            if (data.gridApi.getDisplayedRowCount() === 0) {
                data.gridApi.showNoRowsOverlay()
            }
            const programIdMap = {}
            const programNameMap = {}

            Object.values(data[ACTION_TYPES.PROGRAM].data).forEach((program: any) => {
                programIdMap[program.parent_program_id] = program
                programNameMap[program.program_name] = program
            })
            setProgramNameMap(programNameMap)
            setAllLocalProgramMap(programIdMap)
        }
    }, [
        data[ACTION_TYPES.PROGRAM].loading,
        data[ACTION_TYPES.DELIVERABLES].loading,
        data[ACTION_TYPES.PLAN].loading,
        data[ACTION_TYPES.HC].loading,
        data[ACTION_TYPES.GROUPS_BY_ORG].loading,
        data[ACTION_TYPES.SPEND].loading,
    ])

    useEffect(() => {
        generateCommentFilterOptions()
    }, [programNameMap, allLocalProgramMap])

    const onGridReady = (params: any) => {
        dispatch({ type: 'GRID_API', payload: params.api })
        params.api.autoSizeAllColumns()
    }

    const onProgramGridReady = (params: any) => {
        dispatch({ type: 'PROGRAM_GRID_API', payload: params.api })
    }

    useEffect(() => {
        if (!data.gridApi || isLoading(data)) {
            return
        }
        data.gridApi.updateGridOptions({
            sideBar: selectedTab === SUMMARY_TABS.SPEND ? generateToolSideBar() : undefined,
            groupTotalRow: selectedTab !== SUMMARY_TABS.SPEND ? 'bottom' : undefined,
            grandTotalRow: selectedTab !== SUMMARY_TABS.SPEND ? 'bottom' : undefined,
        })
        setRowsAndColumns(
            selectedTab,
            data,
            initialOrgs,
            initialGroups,
            initialColumns,
            initialRows,
        )
        data.gridApi.autoSizeAllColumns()
    }, [
        data[ACTION_TYPES.REVISION]?.selected,
        data[ACTION_TYPES.PROGRAM]?.selected,
        data[ACTION_TYPES.SCOPED],
        data?.gridApi,
        isLoading(data),
        selectedTab,
        initialOrgs,
        initialGroups,
        initialColumns,
        initialRows,
    ])

    useEffect(() => {
        // Load the column definitions and rows for the particular tab
        if (data[ACTION_TYPES.GROUPS_BY_ORG].loading) {
            return
        }

        setOrgs(setOrgList(data))
        setGroups(setOrgByGroupList(data))
    }, [data[ACTION_TYPES.GROUPS_BY_ORG].loading])

    const getComments = () => {
        const filters = generateFilterPayload(selectedFilters)
        apiClient
            .post(`/query-comment/${pageIdx}`, JSON.stringify(filters))
            .then((res) => {
                setComments(res.data)
            })
            .catch((e) => {
                console.error(e)
            })
    }

    useEffect(() => {
        generateCommentFilterOptions()
    }, [comments])

    useEffect(() => {
        const selectedPlan = data[ACTION_TYPES.PLAN].selected.data
        if (_.isEmpty(selectedPlan)) {
            return
        }
        const newGridOptions = {
            getContextMenuItems: (params) => {
                if (!params.value) {
                    return []
                }

                return getSummaryGetContextMenuItemsGridOption(
                    params,
                    userRolesMetadata,
                    setCommentMode,
                    setIsSidePanelVisible,
                    setSelectedProgram,
                    setSelectedRole,
                    setSelectedDeliverable,
                    setCommentDeliverableOptions,
                    selectedPlan,
                    appContext.stage,
                )
            },
        }
        data.gridApi.updateGridOptions(newGridOptions)
        data.programGridApi?.updateGridOptions(newGridOptions)
        // get all parent program global attributes by op_plan
        getItemsApi(
            apiClient,
            `/op_plan/${encodeURIComponent(`${selectedPlan?.plan_type}#${selectedPlan?.year}`)}/parent_program_global_attributes`,
            setParentProgramGlobalAttributes,
        )
        if (isSidePanelVisible && commentMode === COMMENT_PANEL_MODE.EDIT) {
            setCommentMode(COMMENT_PANEL_MODE.VIEW)
        }
        setSelectedFilters(generateInitialCommentFilters(userRoleSelections, selectedPlan))
    }, [data[ACTION_TYPES.PLAN].selected.data])

    useEffect(() => {
        // get all orgs & Egret groups across
        getItemsApi(apiClient, '/orgs', setAllOrgs)
        getItemsApi(apiClient, '/groups/is_egret/true', setAllGroups, decompressResponse)
    }, [])

    const generateCommentFilterOptions = useCallback(() => {
        const programNameIdMap: { [key: string]: any } = {}
        const userAliasMap: { [key: string]: MUIAutocompleteOption } = {}
        const programOptions: MUIAutocompleteOption[] = []
        let deliverableOptions: MUIAutocompleteOption[] = []

        const planFilterOptions = generateFixedInitialPlanCommentFilter(
            data[ACTION_TYPES.PLAN].selected.data,
        )
        const groupOptions = formatCommentPanelSearchOptions(
            allGroups,
            'group_name',
            'group_id',
            FILTER_PAYLOAD_TYPES.GROUP,
        )
        const orgOptions = formatCommentPanelSearchOptions(
            allOrgs,
            'org_name',
            'org_id',
            FILTER_PAYLOAD_TYPES.ORG,
        )

        if (data[ACTION_TYPES.DELIVERABLES].data) {
            Object.keys(data[ACTION_TYPES.DELIVERABLES].data).forEach((programName) => {
                deliverableOptions = deliverableOptions.concat(
                    data[ACTION_TYPES.DELIVERABLES].data[programName].map((deliverable) => {
                        programNameIdMap[programName] =
                            programNameMap[programName]?.parent_program_id
                        return {
                            label: `${deliverable.deliverable_name} - ${programName}`,
                            type: FILTER_PAYLOAD_TYPES.DELIVERABLE,
                            id: deliverable.deliverable_id,
                        }
                    }),
                )
            })
        }
        Object.keys(programNameIdMap).forEach((program) => {
            programOptions.push({
                label: program,
                type: FILTER_PAYLOAD_TYPES.PROGRAM,
                id: programNameIdMap[program],
            })
        })
        programOptions.sort((a, b) => a.label.localeCompare(b.label))
        deliverableOptions.sort((a: any, b: any) => a.label.localeCompare(b.label))

        comments.forEach((comments) => {
            const userAlias = comments.user_alias
            if (!(userAlias in userAliasMap)) {
                userAliasMap[userAlias] = {
                    label: userAlias,
                    type: FILTER_PAYLOAD_TYPES.USER_ALIAS,
                    id: userAlias,
                }
            }
        })

        setFilterOptions([
            ...planFilterOptions,
            ...programOptions,
            ...deliverableOptions,
            ...orgOptions,
            ...groupOptions,
            ...Object.values(userAliasMap),
        ])
    }, [
        data[ACTION_TYPES.DELIVERABLES].data,
        data[ACTION_TYPES.PLAN].selected,
        data[ACTION_TYPES.PROGRAM].data,
        allLocalProgramMap,
        programNameMap,
        comments,
    ])

    const getDeliverablesForSelectedProgram = (data) => {
        const selectedProgram = data[ACTION_TYPES.PROGRAM]?.selected?.data
        const deliData = data[ACTION_TYPES.DELIVERABLES].data ?? {}
        return deliData[selectedProgram?.program_name ?? ''] ?? []
    }

    return (
        <AppLayout
            disableContentPaddings={true}
            contentType={'table'}
            navigationHide={true}
            toolsHide={true}
            splitPanelOpen={isSidePanelVisible}
            splitPanelPreferences={{ position: 'side' }}
            splitPanel={
                <CommentPanel
                    mode={commentMode}
                    onIsVisibleChange={setIsSidePanelVisible}
                    comments={comments}
                    getComments={getComments}
                    commentDeliverableOptions={commentDeliverableOptions}
                    selectedRole={selectedRole}
                    onSelectedRoleChange={setSelectedRole}
                    selectedProgram={selectedProgram}
                    selectedDeliverable={selectedDeliverable}
                    onSelectedDeliverableChange={setSelectedDeliverable}
                    selectedPlan={data[ACTION_TYPES.PLAN].selected.data}
                    parentProgramGlobalAttributes={parentProgramGlobalAttributes}
                    allLocalProgramMap={allLocalProgramMap}
                    allOrgs={allOrgs}
                    allGroups={allGroups}
                    filterOptions={filterOptions}
                    selectedFilters={selectedFilters}
                    onSelectedFilterChanged={setSelectedFilters}
                />
            }
            content={
                <ContentLayout
                    defaultPadding
                    headerBackgroundStyle={HEADER_BG_COLOR}
                    headerVariant='high-contrast'
                    header={
                        <Box margin={{ left: 's', right: 's' }}>
                            <SpaceBetween size='xs' direction='vertical'>
                                <HeaderTemplate
                                    title='Summaries'
                                    subtitle={SUMMARIES_SUB_DESC}
                                    items={[
                                        { text: 'Home', href: '/' },
                                        { text: `${selectedTab} Summary` },
                                    ]}
                                />
                            </SpaceBetween>
                        </Box>
                    }
                >
                    <Container>
                        <SpaceBetween size='xs' direction='vertical'>
                            {buildTableHeader(
                                selectedTab,
                                data,
                                dispatch,
                                openPreferencesModal,
                                isSidePanelVisible,
                                setIsSidePanelVisible,
                                commentMode,
                                setCommentMode,
                                initialGroups,
                                initialOrgs,
                            )}
                        </SpaceBetween>
                        <Tabs
                            onChange={({ detail }) => {
                                setSelectedTab(detail.activeTabId as SUMMARY_TABS)
                            }}
                            activeTabId={selectedTab}
                            tabs={SUMMARY_TABS_OPTIONS}
                        />
                        <SpaceBetween size={'l'} direction={'vertical'}>
                            {selectedTab === SUMMARY_TABS.PROGRAM && (
                                <>
                                    <div
                                        className='ag-theme-quartz' // applying the grid theme
                                        // the grid will fill the size of the parent container
                                    >
                                        <AgGridReact
                                            onGridReady={onProgramGridReady}
                                            rowData={
                                                data[ACTION_TYPES.PROGRAM]?.selected?.data
                                                    ? [
                                                          {
                                                              ...data[ACTION_TYPES.PROGRAM]
                                                                  ?.selected?.data,
                                                              deliverables:
                                                                  getDeliverablesForSelectedProgram(
                                                                      data,
                                                                  ),
                                                          },
                                                      ]
                                                    : []
                                            }
                                            columnDefs={ProgramTableColumns(setProgramModalVisible)}
                                            gridOptions={{
                                                getContextMenuItems: (params) => {
                                                    if (!params.value) {
                                                        return []
                                                    }

                                                    return getSummaryGetContextMenuItemsGridOption(
                                                        params,
                                                        userRolesMetadata,
                                                        setCommentMode,
                                                        setIsSidePanelVisible,
                                                        setSelectedProgram,
                                                        setSelectedRole,
                                                        setSelectedDeliverable,
                                                        setCommentDeliverableOptions,
                                                        data[ACTION_TYPES.PLAN].selected.data,
                                                        appContext.stage,
                                                    )
                                                },
                                            }}
                                            domLayout='autoHeight'
                                        />
                                    </div>
                                    <ProgramModal
                                        data={data[ACTION_TYPES.PROGRAM]?.selected?.data}
                                        visible={programModalVisible}
                                        setVisible={setProgramModalVisible}
                                    />
                                </>
                            )}
                            <div
                                className='ag-theme-quartz' // applying the grid theme
                                style={{ height: '60vh' }} // the grid will fill the size of the parent container
                            >
                                <Preferences
                                    visible={isPreferencesVisible}
                                    onDismiss={closePreferencesModal}
                                    initialSelectedOrgs={initialOrgs}
                                    initialSelectedGroups={initialGroups}
                                    initialSelectedRows={initialRows}
                                    initialSelectedColumns={initialColumns}
                                    orgs={orgs}
                                    groups={groups}
                                    preferenceType={selectedTab}
                                />
                                <AgGridReact
                                    onGridReady={onGridReady}
                                    gridOptions={{
                                        groupDisplayType: 'custom',
                                        getRowStyle: (params) => {
                                            return {
                                                fontWeight:
                                                    params.node.rowPinned ||
                                                    params.node.footer /*|| params.node*/
                                                        ? 'bold'
                                                        : 'normal',
                                                background: params.node.footer
                                                    ? GRAND_TOTAL_COLOR_CODE
                                                    : NORMAL_CELL_COLOR_CODE,
                                            }
                                        },
                                        pagination: false,
                                        groupTotalRow:
                                            selectedTab !== SUMMARY_TABS.SPEND
                                                ? 'bottom'
                                                : undefined,
                                        grandTotalRow:
                                            selectedTab !== SUMMARY_TABS.SPEND
                                                ? 'bottom'
                                                : undefined,
                                        autoSizeStrategy: {
                                            type: 'fitCellContents',
                                        },
                                        autoGroupColumnDef: {
                                            field: 'program_name_and_priority',
                                            headerName: 'Program',
                                            filter: 'agNumberColumnFilter',
                                            initialWidth: 100,
                                            hide: false,
                                            cellRenderer: PriorityBadgeCellRenderer,
                                            filterParams: {
                                                buttons: ['reset'],
                                                includeBlanksInEquals: false,
                                                includeBlanksInNotEqual: false,
                                                includeBlanksInLessThan: false,
                                                includeBlanksInGreaterThan: false,
                                                includeBlanksInRange: false,
                                            },
                                            filterValueGetter: (params) =>
                                                getProgramPriorityFromGroup(
                                                    params,
                                                    undefined,
                                                    false,
                                                ),
                                            valueGetter: (params) =>
                                                getProgramPriorityFromGroup(params, '', true),
                                            valueFormatter: (params) =>
                                                getProgramPriorityFromGroup(params, '', true),
                                            comparator: priorityComparator,
                                            cellStyle: (params) => generateCellStyle(params),
                                            sort: 'asc',
                                            initialSortIndex: 1,
                                        },
                                        suppressAggFuncInHeader: true,
                                        defaultColDef: {
                                            ...DEFAULT_COL_DEFS,
                                            filter:
                                                selectedTab === SUMMARY_TABS.SPEND
                                                    ? 'agSetColumnFilter'
                                                    : undefined,
                                        },
                                        tooltipShowDelay: 500,
                                        sideBar:
                                            selectedTab !== SUMMARY_TABS.SPEND
                                                ? undefined
                                                : generateToolSideBar(),
                                        getContextMenuItems: (params) => {
                                            if (!params.value) {
                                                return []
                                            }

                                            return getSummaryGetContextMenuItemsGridOption(
                                                params,
                                                userRolesMetadata,
                                                setCommentMode,
                                                setIsSidePanelVisible,
                                                setSelectedProgram,
                                                setSelectedRole,
                                                setSelectedDeliverable,
                                                setCommentDeliverableOptions,
                                                data[ACTION_TYPES.PLAN].selected.data,
                                                appContext.stage,
                                            )
                                        },
                                    }}
                                    rowHeight={ROW_HEIGHT}
                                    headerHeight={ROW_HEIGHT}
                                    onColumnGroupOpened={(e) => e.api.autoSizeAllColumns()}
                                    getRowId={(params: any) => params.data?.row_id}
                                    groupMaintainOrder={true}
                                />
                            </div>
                        </SpaceBetween>
                    </Container>
                </ContentLayout>
            }
        ></AppLayout>
    )
}

function buildTableHeader(
    type: SUMMARY_TABS,
    data,
    dispatch,
    openPreferencesModal,
    isSidePanelVisible,
    setIsSidePanelVisible,
    commentMode,
    setCommentMode,
    initialGroups,
    initialOrgs,
) {
    const [queries, setQueries] = useQueryState()

    const [hcModalVisible, setHCModalVisible] = useState<boolean>(false)
    const appContext = useAppContext()

    const setScopedValue = (val: boolean) => {
        dispatch({
            type: ACTION_TYPES.SCOPED,
            payload: val,
        })
    }

    const getExportFileName = (isDetailsExport) => {
        const defaultSheetName = `${type}Summary`
        if (
            !data[ACTION_TYPES.PLAN].selected?.data ||
            !data[ACTION_TYPES.REVISION].selected?.value
        ) {
            return defaultSheetName
        }
        const plan = data[ACTION_TYPES.PLAN].selected?.data
        const planName = `${plan?.year ?? ''}${plan?.plan_type ?? 'Plan'}`
        const revisions = plan?.revisions ?? []
        const selectedRev = revisions.find(
            (rev) => rev.revision_id === data[ACTION_TYPES.REVISION].selected.value,
        )
        const revName = `Revision${selectedRev?.revision_number ?? ''}`
        const suffix = isDetailsExport ? '-WithDetails' : ''
        return `${defaultSheetName}${suffix}-${planName}-${revName}.xlsx`
    }

    const getExportSheetName = () => {
        return type
    }

    const getSpendColumnsWithDetails = () => {
        if (!data.gridApi) {
            return
        }
        const allColumns = data.gridApi?.getColumns()
        return allColumns.filter((col) => {
            const colDef = col.getColDef()
            if (col.visible || SPEND_HIDDEN_FIELD_IDS.includes(colDef.field)) {
                return col
            }
        })
    }

    const handleClickExportWithPreferences = (isDetailsExport, additionalParameters) => {
        const sheets: any[] = [
            data.gridApi.getSheetDataForExcel({
                sheetName: getExportSheetName(),
            }),
        ]
        sheets.push(
            data.gridApi?.getSheetDataForExcel({
                processHeaderCallback: () => '',
                skipColumnGroupHeaders: true,
                shouldRowBeSkipped: () => true,
                prependContent: getPreferenceData(queries, data, type, initialGroups, initialOrgs),
                sheetName: 'Export Preferences',
            }),
        )
        data.gridApi.exportMultipleSheetsAsExcel({
            data: sheets,
            fileName: getExportFileName(isDetailsExport),
            ...additionalParameters,
        })
    }

    return (
        <>
            <HCDetailModal
                visible={hcModalVisible}
                onVisibleChange={setHCModalVisible}
                // TODO -> complete data
                hrTeamData={{}}
                hrMemberData={{}}
                employeeTypeData={{}}
            />
            <Box>
                <Box float='left'>
                    <Header>{type} Summary</Header>
                </Box>
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='s'>
                        <Box padding={{ top: 's' }}>
                            <SpaceBetween direction='horizontal' size='s'>
                                <CommentIcon
                                    isVisible={isSidePanelVisible}
                                    onVisibleChange={setIsSidePanelVisible}
                                    mode={commentMode}
                                    onModeChange={setCommentMode}
                                    stage={appContext.stage}
                                />
                                <Box padding={{ top: 's' }}>
                                    {type !== SUMMARY_TABS.SPEND ? (
                                        <Button
                                            disabled={isLoading(data)}
                                            disabledReason={
                                                'Fetching data, please wait before exporting.'
                                            }
                                            onClick={() =>
                                                handleClickExportWithPreferences(false, {})
                                            }
                                        >
                                            Export
                                        </Button>
                                    ) : (
                                        <ButtonDropdown
                                            items={[
                                                {
                                                    text: 'Export With Details',
                                                    id: 'export',
                                                    disabled: isLoading(data),
                                                },
                                            ]}
                                            mainAction={{
                                                text: 'Export',
                                                onClick: () => {
                                                    handleClickExportWithPreferences(false, {
                                                        skipColumnGroupHeaders: true,
                                                    })
                                                },
                                                disabled: isLoading(data),
                                            }}
                                            onItemClick={() => {
                                                handleClickExportWithPreferences(true, {
                                                    skipColumnGroupHeaders: true,
                                                    columnKeys: getSpendColumnsWithDetails(),
                                                })
                                            }}
                                            disabled={isLoading(data)}
                                            disabledReason={
                                                'Fetching data, please wait before exporting.'
                                            }
                                        ></ButtonDropdown>
                                    )}
                                </Box>
                                <Box padding={{ top: 's' }}>
                                    <Button onClick={openPreferencesModal}>
                                        Preferences <Icon name='settings' />
                                    </Button>
                                </Box>
                            </SpaceBetween>
                        </Box>
                    </SpaceBetween>
                </Box>
            </Box>
            <SpaceBetween direction='horizontal' size='m'>
                <Select
                    filteringType={'auto'}
                    placeholder={'Selected Plan'}
                    selectedOption={data[ACTION_TYPES.PLAN].selected}
                    onChange={({ detail }) => {
                        const option: any = detail.selectedOption
                        const revisions: any = option?.data.revisions.map(formatRevisionOptions)
                        setQueries({ [PLAN]: detail.selectedOption.value })
                        if (revisions.length) {
                            dispatch({
                                type: 'SET_MANY',
                                payload: {
                                    [ACTION_TYPES.REVISION]: [
                                        { key: 'options', data: revisions },
                                        { key: 'selected', data: revisions[0] },
                                    ],
                                },
                            })
                        } else {
                            dispatch({
                                type: 'SET_MANY',
                                payload: {
                                    [ACTION_TYPES.REVISION]: [
                                        { key: 'options', data: [] },
                                        {
                                            key: 'selected',
                                            data: {
                                                label: 'Select Revision',
                                                value: '',
                                            },
                                        },
                                    ],
                                },
                            })
                        }
                        dispatch({
                            type: ACTION_TYPES.PLAN,
                            payload: { key: 'selected', data: option },
                        })
                    }}
                    options={data[ACTION_TYPES.PLAN].options}
                    disabled={!data[ACTION_TYPES.REVISION].options.length}
                />
                <Select
                    filteringType={'auto'}
                    placeholder={'Selected Revision'}
                    selectedOption={data[ACTION_TYPES.REVISION].selected}
                    onChange={({ detail }) => {
                        setQueries({ [REVISION]: detail.selectedOption.value })
                        dispatch({
                            type: ACTION_TYPES.REVISION,
                            payload: {
                                key: 'selected',
                                data: detail.selectedOption,
                            },
                        })
                    }}
                    options={data[ACTION_TYPES.REVISION].options}
                    disabled={!data[ACTION_TYPES.REVISION].options.length}
                />
                {type === SUMMARY_TABS.PROGRAM && (
                    <Select
                        options={data[ACTION_TYPES.PROGRAM].options}
                        selectedOption={formatSelection(data[ACTION_TYPES.PROGRAM].selected)}
                        onChange={({ detail }) => {
                            setQueries({ [PROGRAM]: detail.selectedOption.value })
                            dispatch({
                                type: ACTION_TYPES.PROGRAM,
                                payload: { key: 'selected', data: detail.selectedOption },
                            })
                        }}
                        filteringType='auto'
                    />
                )}
                {type !== SUMMARY_TABS.SPEND &&
                    generateToggleComponent(
                        'All',
                        'Scoped',
                        data[ACTION_TYPES.SCOPED],
                        setScopedValue,
                    )}
            </SpaceBetween>
        </>
    )
}

export default SummariesAudits
