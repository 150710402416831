import {
    Box,
    Container,
    ContentLayout,
    Header,
    Select,
    SpaceBetween,
    Tabs,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'
import React from 'react'
import AuditDateRangePicker from './AuditDateRangePicker'
import { AUDIT_LOG_GROUPS } from './Constant'
import AuditContext from './AuditContext'
import { AUDIT_STATE_ACTIONS, useAuditState } from './useAuditState'
import DetailsModal from './audit_tables/cell_renderers/DetailsModal'
import GenericAuditTable from './audit_tables/GenericAuditTable'
import { useAppContext } from '../../../context'
import { isGridUnusable } from './AuditUtil'
import { AUDTIS_SUB_DESC } from '../home/HomePageConstants'
import { HEADER_BG_COLOR } from '../plan/Constants'

const AuditsPage = () => {
    const { state, dispatch } = useAuditState()
    const providerState = {
        state,
        dispatch,
    }

    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const onExport = () => {
        state[state.activeTabId].gridApi.exportDataAsExcel()
    }

    const isLoading =
        state.plansMap.loading ||
        state.orgsMap.loading ||
        state.programsByPlan.loading ||
        state.deliverablesByPlan.loading ||
        state.groupsMap.loading ||
        state.revisionsMap.loading ||
        state[AUDIT_LOG_GROUPS.HEADCOUNT].loading ||
        state[AUDIT_LOG_GROUPS.SPEND].loading ||
        state[AUDIT_LOG_GROUPS.PROGRAM_DELIVERABLE].loading ||
        state[AUDIT_LOG_GROUPS.PLAN_REVISION].loading ||
        isGridUnusable(state.gridApi)

    return (
        <AuditContext.Provider value={providerState}>
            <ContentLayout
                defaultPadding
                headerBackgroundStyle={HEADER_BG_COLOR}
                headerVariant='high-contrast'
                header={
                    <Box margin={{ left: 's', right: 's' }}>
                        <SpaceBetween size='xs' direction='vertical'>
                            <HeaderTemplate
                                subtitle={AUDTIS_SUB_DESC}
                                items={[
                                    { text: 'Home', href: '/' },
                                    {
                                        text: 'Audit Dashboard',
                                        href: '',
                                    },
                                ]}
                            />
                        </SpaceBetween>
                    </Box>
                }
            >
                <Box margin={{ left: 's', right: 's' }}>
                    <Container
                        header={
                            <Header
                            //TODO enable once Changes column export done
                            //actions={<Button onClick={onExport}>Export</Button>}
                            >
                                <SpaceBetween direction={'horizontal'} size={'s'}>
                                    <Select
                                        selectedOption={state.selectPlanData.selected}
                                        onChange={({ detail }) => {
                                            dispatch({
                                                type: AUDIT_STATE_ACTIONS.SET_MANY,
                                                payload: {
                                                    selectPlanData: {
                                                        selected: detail.selectedOption,
                                                    },
                                                },
                                            })
                                        }}
                                        options={state.selectPlanData.options}
                                        disabled={!state.selectPlanData.options.length || isLoading}
                                    />
                                    <AuditDateRangePicker />
                                </SpaceBetween>
                            </Header>
                        }
                    >
                        <Tabs
                            onChange={({ detail }) => {
                                dispatch({
                                    type: AUDIT_STATE_ACTIONS.SET_SINGLE,
                                    payload: {
                                        activeTabId: detail.activeTabId,
                                    },
                                })
                            }}
                            activeTabId={state.activeTabId}
                            tabs={[
                                {
                                    label: 'Headcount',
                                    id: AUDIT_LOG_GROUPS.HEADCOUNT,
                                    content: <GenericAuditTable apiClient={apiClient} />,
                                },
                                {
                                    label: 'Program/Deliverable',
                                    id: AUDIT_LOG_GROUPS.PROGRAM_DELIVERABLE,
                                    content: <GenericAuditTable apiClient={apiClient} />,
                                },
                                {
                                    label: 'Plan/Revision',
                                    id: AUDIT_LOG_GROUPS.PLAN_REVISION,
                                    content: <GenericAuditTable apiClient={apiClient} />,
                                },
                                {
                                    label: 'Spend',
                                    id: AUDIT_LOG_GROUPS.SPEND,
                                    content: <GenericAuditTable apiClient={apiClient} />,
                                },
                            ]}
                        />
                        <DetailsModal />
                    </Container>
                </Box>
            </ContentLayout>
        </AuditContext.Provider>
    )
}
export default AuditsPage
