import { useEffect, useState } from 'react'
import { useQueryState } from '../../UseQuery'
import { Modal, TreeSelect } from 'antd'
import { Alert, Button, FormField, SpaceBetween, Toggle } from '@amzn/awsui-components-react'
import { SUMMARY_TABS } from '../../Constant'
import {
    COLUMN_PREFERENCES,
    COLUMNS,
    MAX_GROUP_ALERT,
    MAX_ORG_ALERT,
    MIN_COL_ALERT,
    MIN_GROUP_ALERT,
    MIN_ORG_ALERT,
    MIN_ROW_ALERT,
    parseArrayFromString,
    PREFERENCE_LIMIT,
    PROGRAM_ROW_PREFERENCES,
    ROW_PREFERENCES,
    ROWS,
    TREE_SELECT_WIDTH,
} from './PreferencesUtil'
import { TOTAL_LABEL } from './NewSummariesUtil'
import _ from 'lodash'

export const Preferences = ({
    visible,
    onDismiss,
    initialSelectedOrgs,
    initialSelectedGroups,
    initialSelectedRows,
    initialSelectedColumns,
    orgs,
    groups,
    preferenceType,
}: {
    visible: boolean
    onDismiss: () => void
    initialSelectedOrgs: string[]
    initialSelectedGroups: string[]
    initialSelectedRows: string[]
    initialSelectedColumns: string[]
    orgs: any[]
    groups: any[]
    preferenceType: string
}) => {
    const [selectedOrgs, setSelectedOrgs] = useState<any[]>([])
    const [selectedGroups, setSelectedGroups] = useState<any[]>([])

    const [selectedRows, setSelectedRows] = useState<any[]>([])
    const [selectedColumns, setSelectedColumns] = useState<any[]>([])

    const [queries, setQueries] = useQueryState()

    const [searchOrgs, setSearchOrgs] = useState('')
    const [searchGroups, setSearchGroups] = useState('')
    const [filteredOrgs, setFilteredOrgs] = useState(orgs)
    const [filteredGroups, setFilteredGroups] = useState(groups)
    const [alertMessage, setAlertMessage] = useState('')

    // For TreeNode selection
    const [visibleNodes, setVisibleNodes] = useState<Set<string>>(new Set())
    const [searchValue, setSearchValue] = useState<string>('')

    const rowPreference =
        preferenceType === SUMMARY_TABS.PROGRAM ? PROGRAM_ROW_PREFERENCES : ROW_PREFERENCES

    useEffect(() => {
        // Set local states when opening the modal
        if (visible) {
            setSelectedGroups(
                parseArrayFromString(queries[SUMMARY_TABS.GROUP], initialSelectedGroups),
            )
            setSelectedOrgs(parseArrayFromString(queries[SUMMARY_TABS.ORG], initialSelectedOrgs))
            setSelectedRows(parseArrayFromString(queries[ROWS], initialSelectedRows))
            setSelectedColumns(parseArrayFromString(queries[COLUMNS], initialSelectedColumns))
        } else {
            // clear out alert when closing modal
            setAlertMessage('')
        }
    }, [visible])

    const handleToggle = (id: string, type: 'column' | 'row') => {
        const updateFunctionMap = {
            column: {
                selected: selectedColumns,
                setSelected: setSelectedColumns,
            },
            row: { selected: selectedRows, setSelected: setSelectedRows },
        }

        const { selected, setSelected } = updateFunctionMap[type]

        const updatedValues = selected.includes(id)
            ? selected.filter((itemId) => itemId !== id)
            : [...selected, id]

        setSelected(updatedValues)
    }

    useEffect(() => {
        setFilteredOrgs(
            orgs.filter((org) => org.label.toLowerCase().includes(searchOrgs.toLowerCase())),
        )
    }, [searchOrgs, orgs])

    useEffect(() => {
        setFilteredGroups(
            groups.filter((group) =>
                group.label.toLowerCase().includes(searchGroups.toLowerCase()),
            ),
        )
    }, [searchGroups, groups])

    const OrgOptions = orgs
        .map((token) => ({
            value: token.id,
            title: token.label,
            id: token.id,
            key: token.id,
        }))
        .sort((a, b) => a.title.localeCompare(b.title))

    const GroupOptions = groups
        .map((token) => ({
            ...token,
            children: token.children.sort((a, b) => a.title.localeCompare(b.title)),
        }))
        .sort((a, b) => a.title.localeCompare(b.title))

    const validatePreferences = () => {
        if (selectedGroups.length >= PREFERENCE_LIMIT) {
            return MAX_GROUP_ALERT
        } else if (_.isEmpty(selectedGroups)) {
            return MIN_GROUP_ALERT
        }

        if (selectedOrgs.length >= PREFERENCE_LIMIT) {
            return MAX_ORG_ALERT
        } else if (_.isEmpty(selectedOrgs)) {
            return MIN_ORG_ALERT
        }

        if (_.isEmpty(selectedColumns)) {
            return MIN_COL_ALERT
        }
        if (_.isEmpty(selectedRows)) {
            return MIN_ROW_ALERT
        }

        return ''
    }

    const applyPreferences = () => {
        const errorMsg = validatePreferences()
        if (errorMsg) {
            setAlertMessage(errorMsg)
            return
        }

        setQueries({
            [SUMMARY_TABS.GROUP]: selectedGroups.join(';'),
            [SUMMARY_TABS.ORG]: selectedOrgs.join(';'),
            [SUMMARY_TABS.SPEND]: selectedOrgs.join(';'),
            [COLUMNS]: selectedColumns.join(';'),
            [ROWS]: selectedRows.join(';'),
        })

        //close the modal after applying preferences
        onDismiss()
    }

    return (
        <Modal
            onCancel={onDismiss}
            open={visible}
            title={`${preferenceType} Preferences`}
            footer={[
                <Button variant='link' onClick={onDismiss}>
                    Cancel
                </Button>,
                <Button variant='primary' onClick={applyPreferences}>
                    Apply
                </Button>,
            ]}
        >
            <SpaceBetween direction='vertical' size='xs'>
                {alertMessage && (
                    <Alert
                        dismissible
                        type='error'
                        header='Invalid Operation'
                        onDismiss={() => {
                            setAlertMessage('')
                        }}
                    >
                        {alertMessage}
                    </Alert>
                )}
                {[SUMMARY_TABS.ORG, SUMMARY_TABS.PROGRAM, SUMMARY_TABS.SPEND]
                    .map(String)
                    .includes(preferenceType) && (
                    <FormField
                        label={'Organizations'}
                        description={`Customize the organization visibility`}
                    >
                        <TreeSelect
                            treeData={OrgOptions}
                            value={selectedOrgs}
                            treeCheckable
                            treeDefaultExpandAll
                            multiple
                            onChange={(newValue: string[]) => setSelectedOrgs(newValue)}
                            placeholder={'Add Orgs to the Table'}
                            style={{ width: TREE_SELECT_WIDTH }}
                            allowClear
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            listHeight={512}
                            filterTreeNode
                        />
                    </FormField>
                )}
                {preferenceType === SUMMARY_TABS.GROUP && (
                    <FormField label={'Groups'} description={`Customize the group visibility`}>
                        <TreeSelect
                            treeData={GroupOptions}
                            value={selectedGroups}
                            treeCheckable
                            treeDefaultExpandAll
                            multiple
                            onChange={(newValue: string[]) => setSelectedGroups(newValue)}
                            showCheckedStrategy={TreeSelect.SHOW_CHILD}
                            placeholder={'Add Groups to the Table'}
                            style={{ width: TREE_SELECT_WIDTH }}
                            allowClear
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            listHeight={512}
                            showSearch
                            autoClearSearchValue
                            filterTreeNode
                            searchValue={searchValue}
                            onSearch={(value: string) => {
                                setSearchValue(value)
                                // Update visible nodes based on search
                                const newVisibleNodes = new Set<string>()
                                const checkNode = (node: any) => {
                                    const title = node.title?.toString() || ''
                                    const matches = title
                                        .toLowerCase()
                                        .includes(value.toLowerCase())

                                    if (matches) {
                                        newVisibleNodes.add(node.value)
                                    }
                                    node.children?.forEach(checkNode)
                                }
                                GroupOptions.forEach(checkNode)
                                setVisibleNodes(newVisibleNodes)
                            }}
                            onSelect={(checkedKeys, node) => {
                                // If it's a parent node being checked, else handle normally
                                if (node?.children && searchValue) {
                                    const visibleChildrenKeys = node.children
                                        .filter((child) => visibleNodes.has(child.value))
                                        .map((child) => child.value)

                                    const newSelectedKeys = [
                                        ...selectedGroups,
                                        ...visibleChildrenKeys,
                                    ]
                                    setSelectedGroups(newSelectedKeys)
                                }

                                setSearchValue('')
                            }}
                        />
                    </FormField>
                )}
                {preferenceType !== SUMMARY_TABS.SPEND && (
                    <FormField label='Columns' description='Customize the column visibility'>
                        {COLUMN_PREFERENCES.map((col) => (
                            <Toggle
                                key={col.id}
                                checked={selectedColumns.includes(col.id)}
                                onChange={() => handleToggle(col.id, 'column')}
                            >
                                {col.id === TOTAL_LABEL && preferenceType === SUMMARY_TABS.GROUP
                                    ? 'Group Totals'
                                    : col.label}
                            </Toggle>
                        ))}
                    </FormField>
                )}
                {preferenceType !== SUMMARY_TABS.SPEND && (
                    <FormField label='Rows' description='Customize the row visibility'>
                        {rowPreference.map((row) => (
                            <Toggle
                                key={row.id}
                                checked={selectedRows.includes(row.id)}
                                onChange={() => handleToggle(row.id, 'row')}
                            >
                                {row.label}
                            </Toggle>
                        ))}
                    </FormField>
                )}
            </SpaceBetween>
        </Modal>
    )
}

export default Preferences
