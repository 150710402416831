import { Box, TextContent } from '@amzn/awsui-components-react'
import { styleHeaderWithNoWrap } from '../../common/Util'
import { roundSingleItem } from '../program/deliverables/DeliverableConstants'
import { isCellById } from '../program/SandboxUtils'
import { addCommentMenuItems } from '../comment/CommentUtil'

export const GRAND_TOTAL_COLOR_CODE = '#AEAEAE'
export const NORMAL_CELL_COLOR_CODE = '#FFFFFF'
export const HISTORY_BADGE_BLUE = '#B5D6F4'

export const HISTORY_BADGE_GREY = '#D1D5DB'

export const HISTORY_PANEL_ORG_MAX_WIDTH = 10
export const HISTORY_PANEL_GROUP_MAX_TEXT_LENGTH = 25
export const HISTORY_PANEL_PROG_DELI_MAX_TEXT_LENGTH = 15
export const DEFAULT_COL_WIDTH = 80
export const DEFAULT_NAME_COL_WIDTH = 300
export const EMPTY_BIS_ROW = { program: 'BIS', total_ct_id: 0, total_ff_id: 0 }

export const hideZerosAndTruncateNumbers = (params) => {
    return params.value !== 0 ? params.value?.toFixed(2) : ''
}

export const generateCellTooltip = (params) => {
    const colId = params.column.colId
    if (params.data) {
        if (params.data[`${colId}_locked`] !== undefined && params.data[`${colId}_locked`]) {
            return params.data[`${colId}_locked_user`]
        } else if (params.data[colId] != undefined) {
            return `Previous Value: ${params.data[`${colId}_previous_value`]} \v Updated By: ${params.data[`${colId}_updated_by`]} \v Updated At: ${params.data[`${colId}_updated_at`]?.split('T')[0]}`
        } else {
            return
        }
    }
}

export const formatSelection = (object): any => {
    return {
        value: object.value,
        label: (
            <TextContent>
                <p> {`${object.label}`} </p>
            </TextContent>
        ),
    }
}

const rightJustify = (content, calculationFieldName) => {
    return (
        <Box float={'right'} fontWeight={calculationFieldName?.includes('Gap') ? 'bold' : 'normal'}>
            {content}
        </Box>
    )
}

export const boldSpecificRowContent = (content, calculationFieldName, substring) => {
    return (
        <Box fontWeight={calculationFieldName?.includes(substring) ? 'bold' : 'normal'}>
            {content}
        </Box>
    )
}

const centerAndBold = (content) => {
    return (
        <Box textAlign={'right'} fontWeight={'bold'} color={'text-body-secondary'}>
            {content}
        </Box>
    )
}
const MAX_ITEM_WIDTH = 8
const MAX_CALCULATION_COL_WIDTH = 30

const commonColumnDef = [
    {
        id: 'calculation',
        header: '',
        cell: (e: any) => rightJustify(e.calculation, e.calculation),
        sortingField: 'calculation',
        maxWidth: MAX_CALCULATION_COL_WIDTH,
    },
    {
        id: 'ct',
        header: centerAndBold('C&T'),
        cell: (e: any) => rightJustify(roundSingleItem(e.ct), e.calculation),
        sortingField: 'ct',
        maxWidth: MAX_ITEM_WIDTH,
    },
    {
        id: 'ff',
        header: centerAndBold('FF'),
        cell: (e: any) => rightJustify(roundSingleItem(e.ff), e.calculation),
        sortingField: 'ff',
        maxWidth: MAX_ITEM_WIDTH,
    },
    {
        id: 'hc',
        header: styleHeaderWithNoWrap(
            centerAndBold(
                <>
                    HC <br></br>C&T + FF
                </>,
            ),
        ),
        cell: (e: any) => rightJustify(roundSingleItem(e.hc), e.calculation),
        sortingField: 'hc',
        maxWidth: MAX_ITEM_WIDTH,
    },
]

export const planDataColumnDef = [
    ...commonColumnDef,
    ...[
        {
            id: 'plan_name',
            header: '',
            cell: (e: any) => rightJustify(e.plan_name, ''),
            sortingField: 'plan_name',
        },
    ],
]

const customProgramHCSort = (item1, item2) => {
    if (!item1.priority) {
        return 1
    }
    if (!item2.priority) {
        return -1
    }
    return parseInt(item1.priority) < parseInt(item2.priority) ? -1 : 1
}

export const generatePlanProgramHCColumnDef = (planName) => {
    return [
        {
            id: 'priority',
            header: 'Priority',
            cell: (e: any) => e.priority,
            sortingField: 'priority',
            exportColumnName: 'Priority',
            sortingComparator: (item1, item2) => customProgramHCSort(item1, item2),
        },
        {
            id: 'program',
            header: 'Program',
            cell: (e: any) => e.program,
            exportColumnName: 'Program',
            sortingField: 'program',
        },
        {
            id: 'total',
            header: planName ? `${planName} HC` : `Total HC`,
            cell: (e: any) => rightJustify(roundSingleItem(e.total), ''),
            exportColumnName: planName ? `${planName} HC` : `Total HC`,
            sortingField: 'total',
        },
        {
            id: 'ct',
            header: 'C&T',
            cell: (e: any) => rightJustify(roundSingleItem(e.ct), ''),
            exportColumnName: 'CT',
            sortingField: 'ct',
        },
        {
            id: 'ff',
            header: 'FF',
            cell: (e: any) => rightJustify(roundSingleItem(e.ff), ''),
            exportColumnName: 'FF',
            sortingField: 'ff',
        },
    ]
}

const flagColumns = ['is_estimated', 'is_scoped']
export const generateExportVisibleColumns = (columns) => {
    const visible: string[] = []
    columns.forEach((field) => {
        if (!flagColumns.includes(field.id)) {
            visible.push(field.id)
        }
    })
    return visible
}

export const visibleColumns = ['calculation', 'hc', 'ct', 'ff']
export const HC_GAP = 'Gap (Incremental HC Ask)'
export const groupDataCalculations = ['BIS', 'Total', 'Total w/ OH', HC_GAP]

const nullColumnNames = ['ct', 'ff', 'headcount', 'total']

const checkIfValueIsZero = (val: any) => {
    return parseFloat(val) === 0
}
export const nullOutZeroValues = (rows) => {
    return rows.map((row) => {
        const updated_row = {}
        Object.keys(row).forEach((element) => {
            if (
                nullColumnNames.some((substring) => element.includes(substring)) &&
                checkIfValueIsZero(row[element])
            ) {
                updated_row[element] = ''
            } else {
                updated_row[element] = row[element]
            }
        })
        return updated_row
    })
}

export const orgSummaryInitialColDef: any[] = [
    {
        headerName: 'Program',
        field: 'program_name',
        rowGroup: true,
        hide: true,
        pinned: 'left',
        headerTooltip: 'Program',
    },
    {
        headerName: 'Deliverable',
        field: 'deliverable_name',
        pinned: 'left',
        headerTooltip: 'Deliverable',
    },
]

export const getSummaryGetContextMenuItemsGridOption = (
    params,
    userRolesMetadata,
    setCommentMode,
    setIsSidePanelVisible,
    setSelectedProgram,
    setSelectedRole,
    setSelectedDeliverable,
    setCommentDeliverableOptions,
    selectedPlan,
    stage,
) => {
    return isCellById(params, 'program_name_and_priority') || isCellById(params, 'program_name')
        ? [
              addCommentMenuItems(
                  true,
                  userRolesMetadata,
                  setCommentMode,
                  setIsSidePanelVisible,
                  setSelectedProgram,
                  setSelectedRole,
                  setSelectedDeliverable,
                  setCommentDeliverableOptions,
                  selectedPlan,
              ),
          ]
        : isCellById(params, 'deliverable_name')
          ? [
                addCommentMenuItems(
                    false,
                    userRolesMetadata,
                    setCommentMode,
                    setIsSidePanelVisible,
                    setSelectedProgram,
                    setSelectedRole,
                    setSelectedDeliverable,
                    setCommentDeliverableOptions,
                    selectedPlan,
                ),
            ]
          : []
}
