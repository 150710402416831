import { useEffect, useState } from 'react'
import {
    Alert,
    Box,
    Container,
    ContentLayout,
    Flashbar,
    Link,
    SpaceBetween,
    StatusIndicator,
    Tabs,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'
import {
    ALERT_TYPES,
    DESCRIPTION_LENGTH_LIMIT,
    MODAL_MODES,
    STATUS_INDICATOR_TYPES,
} from '../../Constant'
import { AMZN_TEAM_BASE_URL, CREATE_TEAM_REQUEST } from '../../common/LinkUtil'
import DeleteModal from '../reusable/DeleteModal'
import { useAppContext } from '../../../context'
import { useLocation } from 'react-router-dom'
import { ALERT_TEMPLATE, LIMITED_ACCESS_MESSAGE, NO_PERMISSION_MESSAGE } from '../reusable/TextUtil'
import OrgGroupList from './OrgGroupList'
import { convertToLocalTime, getEgretGroups } from '../../common/Util'
import DatePopover from '../reusable/DatePopover'
import TextPopover from '../reusable/TextPopover'
import StatusInfoPopover from '../reusable/StatusInfoPopover'
import CreateGroup from './CreateGroup'
import MoveGroups from './MoveGroups'
import { ROLES } from '../../../role'
import useStore from '../../Store'
import { HEADER_BG_COLOR } from '../plan/Constants'
import { GROUP_SUB_DESC } from '../home/HomePageConstants'

export const groupListColumnDef = [
    {
        id: 'group_name',
        header: 'Group',
        cell: (e: any) => e.group_name,
        exportColumnName: 'Group',
        sortingField: 'group_name',
    },
    {
        id: 'org_id',
        header: 'Org ID',
        cell: (e: any) => e.org_id,
    },
    {
        id: 'org_name',
        header: 'Org',
        cell: (e: any) => e.org_name,
        exportColumnName: 'Organization',
        maxWidth: 100,
    },
    {
        id: 'description',
        header: 'Description',
        cell: (e: any) => (
            <TextPopover text={e.description} lengthLimit={DESCRIPTION_LENGTH_LIMIT} />
        ),
        exportColumnName: 'Description',
        maxWidth: 200,
    },
    {
        id: 'hr_permission_group',
        header: (
            <StatusInfoPopover
                title='Headcount Source'
                popoverContent='Amazon TEAMS ID (example: amzn1.abacus.team.hdobhgnhqu7ctplusltq). Tracks team headcount.'
                size='large'
            />
        ),
        cell: (e: any) =>
            e.hr_permission_group && e.hr_permission_group.length > 0 ? (
                <Link
                    //TODO add api to get TEAMS name
                    href={`${AMZN_TEAM_BASE_URL}/${e.hr_permission_group}`}
                    external
                >
                    {e.hr_permission_group_name ? e.hr_permission_group_name : 'Open'}
                </Link>
            ) : (
                <></>
            ),
        sortingField: 'hr_permission_group_name',
        exportColumnName: 'Headcount Source',
        maxWidth: 100,
    },
    {
        id: 'created_by',
        header: 'Created by',
        cell: (e) => e.created_by,
        sortingField: 'created_by',
    },
    {
        id: 'updated_by',
        header: 'Updated by',
        cell: (e) => e.updated_by,
        exportColumnName: 'Updated By',
        sortingField: 'updated_by',
    },
    {
        id: 'created_at',
        header: 'Created at',
        cell: (e) => <DatePopover date={convertToLocalTime(e.created_at)} />,
        sortingField: 'created_at',
    },
    {
        id: 'updated_at',
        header: 'Updated at',
        cell: (e) => <DatePopover date={convertToLocalTime(e.updated_at)} />,
        exportColumnName: 'Updated At',
        sortingField: 'updated_at',
    },
    {
        id: 'primary_alias',
        header: 'Primary Contact',
        cell: (e) => e.primary_alias,
        exportColumnName: 'Primary Contact',
        sortingField: 'primary_alias',
    },
    {
        id: 'registered_users',
        header: 'Group Managers',
        cell: (e) => {
            if (e.registered_users && e.registered_users.length > 0) {
                return e.registered_users.toString()
            } else {
                return '-'
            }
        },
        exportColumnName: 'Group Managers',
        maxWidth: 180,
    },
    {
        id: 'is_active',
        header: 'Active',
        cell: (e: any) =>
            e.is_active ? (
                <StatusIndicator type={STATUS_INDICATOR_TYPES.SUCCESS}>Yes</StatusIndicator>
            ) : (
                <StatusIndicator type={STATUS_INDICATOR_TYPES.WARNING}>No</StatusIndicator>
            ),
        sortingField: 'is_active',
    },
    {
        id: 'is_egret',
        header: 'Egret Usage',
        cell: (e: any) =>
            e.is_egret ? (
                <StatusIndicator type={STATUS_INDICATOR_TYPES.SUCCESS}>Yes</StatusIndicator>
            ) : (
                <StatusIndicator type={STATUS_INDICATOR_TYPES.WARNING}>No</StatusIndicator>
            ),
        sortingField: 'is_egret',
    },
    {
        id: 'is_falcon',
        header: 'Falcon Usage',
        cell: (e: any) =>
            e.is_falcon ? (
                <StatusIndicator type={STATUS_INDICATOR_TYPES.SUCCESS}>Yes</StatusIndicator>
            ) : (
                <StatusIndicator type={STATUS_INDICATOR_TYPES.WARNING}>No</StatusIndicator>
            ),
        sortingField: 'is_falcon',
    },
]
const OrgDetail = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const location: any = useLocation()
    const path = location.pathname.split('/')
    let orgId: string
    if (path.length >= 3 && path[1] === 'org') {
        orgId = path[2]
    } else {
        orgId = ''
    }

    const canAdmin = useStore((state) => state.canAdmin)
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)

    const [modalMode, setModalMode] = useState<MODAL_MODES>(MODAL_MODES.NOT_SET)
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [deleteGroupModalVisible, setDeleteGroupModalVisible] = useState(false)
    const [groups, setGroups] = useState([])
    const [visibleGroups, setVisibleGroups] = useState<any[]>([])
    const [showAllGroups, setShowAllGroups] = useState(false)
    const [isGroupLoading, setIsGroupLoading] = useState(false)
    const [selectedGroups, setSelectedGroups] = useState<any>([])
    const [orgName, setOrgName] = useState('')
    const [hasAuth, setHasAuth] = useState(true)
    const [moveGroupModalVisible, setMoveGroupModalVisible] = useState(false)
    const [alertContent, setAlertContent] = useState<string>('')
    const [alertType, setAlertType] = useState<ALERT_TYPES>(ALERT_TYPES.INFO)
    const [alertItems, setAlertItems] = useState<any>([])
    useEffect(() => {
        const itemOptions = showAllGroups ? [...groups] : getEgretGroups([...groups])
        setVisibleGroups(itemOptions)
    }, [groups, showAllGroups])

    const getAllGroupsUnderOrg = (orgId) => {
        apiClient
            .get(`/falcon/orgs/${orgId}/groups`)
            .then((response) => {
                const allGps = response.data.sort((gp1, gp2) =>
                    gp1?.is_egret === gp2?.is_egret
                        ? gp1.group_name < gp2.group_name
                            ? -1
                            : 1
                        : gp1?.is_egret
                          ? -1
                          : 1,
                )
                setGroups(allGps)
                setVisibleGroups(showAllGroups ? [...allGps] : getEgretGroups([...allGps]))
                setIsGroupLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setIsGroupLoading(false)
            })
    }

    const groupVisibleColumns = [
        'group_name',
        'description',
        'hr_permission_group',
        'created_by',
        'updated_by',
        'created_at',
        'updated_at',
        'primary_alias',
        'registered_users',
        'is_active',
    ]

    const adminOnlyColumns = ['is_egret', 'is_falcon']

    const handleGroupEdit = () => {
        setModalMode(MODAL_MODES.EDIT)
        setCreateModalVisible(true)
    }

    const handleGroupCreate = () => {
        setModalMode(MODAL_MODES.CREATE)
        setCreateModalVisible(true)
    }

    const handleDeleteGroups = () => {
        setDeleteGroupModalVisible(true)
    }

    const handleMoveGroups = () => {
        setMoveGroupModalVisible(true)
    }

    const handleMoveGroupsModalClose = () => {
        setMoveGroupModalVisible(false)
    }

    const handleCreateModalClose = () => {
        setCreateModalVisible(false)
    }

    const getOrgInfo = (orgId) => {
        apiClient
            .get(`/falcon/org/${orgId}`)
            .then((response) => {
                const data = response.data
                apiClient
                    .get(`/falcon/user-business-entity/${data.business_entity_id}/has-auth`)
                    .then((response) => {
                        const res = response.data
                        setHasAuth(res)
                        if (res) {
                            setOrgName(data['org_name'])
                            getAllGroupsUnderOrg(orgId)
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                        setIsGroupLoading(false)
                    })
            })
            .catch((error) => {
                console.error(error)
                setIsGroupLoading(false)
            })
    }

    const deleteGroupRegisteredUsersPermissions = (registeredUsers) => {
        apiClient
            .delete(
                `/users/role/${ROLES.GROUP_MANAGER}?aliases=${registeredUsers.join(
                    ',',
                )}&business_entity_id=${selectedBusinessEntity.business_entity_id}&object_ids=${
                    selectedGroups[0].group_id
                }`,
            )
            .then(() => {
                setAlertType(ALERT_TYPES.SUCCESS)
                setAlertContent(
                    `Successfully removed registered user(s) from ${selectedGroups[0].group_name}.`,
                )
            })
            .catch((error) => {
                console.error(error)
                setAlertType(ALERT_TYPES.ERROR)
                setAlertContent(
                    `Failed to remove register user(s) from ${selectedGroups[0].group_name}.`,
                )
            })
    }

    const deleteGroups = () => {
        const group_ids = selectedGroups.map((group) => group['group_id']).join(',')
        const url = `/falcon/orgs/${orgId}/groups?group_ids=${group_ids}`
        setDeleteGroupModalVisible(false)
        setIsGroupLoading(true)
        apiClient
            .delete(url)
            .then(() => {
                const allCurrentUsers = [
                    ...selectedGroups[0].registered_users,
                    ...(selectedGroups[0].primary_alias ? [selectedGroups[0].primary_alias] : []),
                ]
                if (allCurrentUsers.length) {
                    deleteGroupRegisteredUsersPermissions(allCurrentUsers)
                }
                getAllGroupsUnderOrg(orgId)
                setAlertType(ALERT_TYPES.SUCCESS)
                setAlertContent(`Successfully deleted group ${selectedGroups[0].group_name}.`)
                setSelectedGroups([])
            })
            .catch((error) => {
                setAlertType(ALERT_TYPES.ERROR)
                setAlertContent(`Failed to delete because group: ${error.response.data}.`)
                setSelectedGroups([])
                setIsGroupLoading(false)
                console.error(error)
            })
    }

    useEffect(() => {
        setIsGroupLoading(true)
        getOrgInfo(orgId)
    }, [alertItems])

    return (
        <ContentLayout
            defaultPadding
            headerBackgroundStyle={HEADER_BG_COLOR}
            headerVariant='high-contrast'
            header={
                <Box margin={{ left: 's', right: 's' }}>
                    <SpaceBetween direction='vertical' size='s'>
                        <HeaderTemplate
                            subtitle={GROUP_SUB_DESC}
                            items={[
                                { text: 'Home', href: '/' },
                                { text: 'Organizations', href: '/orgs' },
                                { text: `${orgName}`, href: '' },
                            ]}
                        />
                        {!hasAuth ? (
                            <ALERT_TEMPLATE
                                type={ALERT_TYPES.WARNING}
                                header='Access Denied'
                                text={<NO_PERMISSION_MESSAGE />}
                            />
                        ) : canAdmin ? (
                            <></>
                        ) : (
                            <Alert header='Limited Access'>
                                <LIMITED_ACCESS_MESSAGE
                                    item='group/team'
                                    link={CREATE_TEAM_REQUEST}
                                />
                            </Alert>
                        )}
                        {alertContent === '' ? (
                            <></>
                        ) : (
                            <Flashbar
                                items={[
                                    {
                                        onDismiss: () => {
                                            setAlertContent('')
                                        },
                                        dismissible: true,
                                        content: alertContent,
                                        type: alertType,
                                    },
                                ]}
                            />
                        )}
                    </SpaceBetween>
                </Box>
            }
        >
            <DeleteModal
                title='Group(s)'
                visible={deleteGroupModalVisible}
                onDismiss={() => setDeleteGroupModalVisible(false)}
                onDelete={deleteGroups}
            />
            <Box margin={{ left: 's', right: 's' }}>
                <Container>
                    <Tabs
                        tabs={[
                            {
                                label: 'Groups',
                                id: `${orgName}#groups`,
                                content: (
                                    <Box>
                                        <OrgGroupList
                                            canAdmin={canAdmin}
                                            orgName={orgName}
                                            groups={visibleGroups}
                                            isLoading={isGroupLoading}
                                            columns={groupListColumnDef}
                                            visibleColumns={
                                                canAdmin
                                                    ? groupVisibleColumns.concat(adminOnlyColumns)
                                                    : groupVisibleColumns
                                            }
                                            onCreate={handleGroupCreate}
                                            onEdit={handleGroupEdit}
                                            onDelete={handleDeleteGroups}
                                            onMove={handleMoveGroups}
                                            selectedGroups={selectedGroups}
                                            onSelectedGroupsChange={setSelectedGroups}
                                            showAllGroups={showAllGroups}
                                            setShowAllGroups={setShowAllGroups}
                                        />
                                        <CreateGroup
                                            canAdmin={canAdmin}
                                            modalMode={modalMode}
                                            modalVisible={createModalVisible}
                                            closeModalHandler={handleCreateModalClose}
                                            orgId={orgId}
                                            orgName={orgName}
                                            groups={groups}
                                            refreshList={() => getAllGroupsUnderOrg(orgId)}
                                            onGroupLoadingChange={setIsGroupLoading}
                                            setAlertContent={setAlertContent}
                                            setAlertType={setAlertType}
                                            selectedGroups={selectedGroups}
                                            setSelectedGroups={setSelectedGroups}
                                        />
                                        <MoveGroups
                                            modalVisible={moveGroupModalVisible}
                                            closeModalHandler={handleMoveGroupsModalClose}
                                            selectedGroups={selectedGroups}
                                            setSelectedGroups={setSelectedGroups}
                                            setAlertItems={setAlertItems}
                                        />
                                    </Box>
                                ),
                            },
                        ]}
                    />
                </Container>
            </Box>
        </ContentLayout>
    )
}

export default OrgDetail
